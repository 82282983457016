import React, { Component } from "react";
import API from "../../config/config";
import { Button, Row, Col, Card, CardBody, Container, Table } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class ResponsiveTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Tables", link: "#" },
        { title: "Responsive Table", link: "#" },
      ],
      tableData: [],
    };
  }

  componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
    };

    fetch(API.api_route + `/v1/share/all`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({
            tableData: data.data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Responsive Table"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Example</h4>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          striped
                          bordered
                          responsive
                        >
                          <thead>
                            <tr>
                              <th data-priority="3">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tableData.map((itm) => (
                              <tr>
                                <td>
                                  <Button
                                    onClick={() => {
                                      window.open(
                                        `http://localhost:3000/watch/${itm[0]}`
                                      );
                                    }}
                                  >
                                    View
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ResponsiveTables;
