import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Alert,
  DropdownItem,
  FormGroup,
  Label,
} from "reactstrap";
import "./ProfileMenu.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
//i18n
import { withNamespaces } from "react-i18next";
import { Modal, Button, message, notification } from "antd";
// users
import avatar1 from "../../../assets/images/logout.ico";
import avatar2 from "../../../assets/images/shutdown2.png";

import API from "../../../config/config";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      isModalVisible: false,
      username: "",
      regAdd: "",
      gstin: "",
      msgAlert: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.fetchUserDetails();
  }

  fetchInvoice = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
    };
    fetch(API.api_route + "/v1/user/invoice", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.status);
        if (data.status === "failed") {
          if (data.reason === "UnAuthorized Access") {
            notification.error({ message: data.reason });
            localStorage.removeItem("token");
            window.location.reload();
          }
        } else {
          message.success("Invoice has been requested");
        }
      })
      .catch((e) => {
        notification.error({ message: "UnAuthorized Access" });
        console.log(e);
      });
  };

  fetchUserDetails = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
    };

    fetch(API.api_route + "/v1/user/profile", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.status);
        if (data.status === "failed") {
          if (data.reason === "UnAuthorized Access") {
            notification.error({ message: data.reason });
            localStorage.removeItem("token");
            window.location.reload();
          }
        } else {
          this.setState({
            username: data.name,
            gstin: data.gst,
            regAdd: data.state,
          });
        }
      })
      .catch((e) => {
        notification.error({ message: "UnAuthorized Access" });
        console.log(e);
      });
  };

  showAlert = () => {
    this.setState({
      msgAlert: true,
    });
    setTimeout(() => {
      this.setState({
        msgAlert: false,
      });
    }, 2000);
  };

  handleForm = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  sendData = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        name: this.state.username,
        state: this.state.regAdd,
        gst: this.state.gstin,
      }),
    };

    fetch(API.api_route + "/v1/user/profile/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.status);
        if (data.status === "success") {
          message.success("Successfully saved user profile");
        } else if (data.status === "failed") {
          if (data.reason === "UnAuthorized Access") {
            notification.error({ message: data.reason });
            localStorage.removeItem("token");
            window.location.reload();
          }
        }
      })
      .catch((e) => {
        notification.error({ message: "Error" });
        console.log(e);
      });
  };

  handleOk = () => {
    if (
      this.state.username === "" ||
      this.state.gstin === "" ||
      this.state.regAdd === ""
    ) {
      this.showAlert();
    } else {
      this.sendData();

      this.setState({
        isModalVisible: false,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  hover() {
    // console.log("hove");
  }

  unhover() {
    // console.log("hovet");
  }

  handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  render() {
    let username = "Admin";
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const uNm = obj.email.split("@")[0];
      username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    }

    return (
      <React.Fragment>
        <Modal
          title="User Profile"
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <AvForm>
            <FormGroup className="auth-form-group-custom mb-2">
              <i className="ri-user-2-line auti-custom-input-icon"></i>
              <Label htmlFor="userpassword">Name/Company Name</Label>
              <AvField
                name="userEmail"
                type="text"
                onChange={this.handleForm}
                className="form-control"
                defaultValue={
                  this.state.username === "" ? "" : this.state.username
                }
                id="username"
                placeholder="Enter Name"
              />
            </FormGroup>
            <FormGroup className="auth-form-group-custom mb-2">
              <i className="ri-mail-line auti-custom-input-icon"></i>
              <Label htmlFor="userpassword">Registered Address</Label>
              <AvField
                name="otp"
                //   value={this.state.password}
                type="text"
                onChange={this.handleForm}
                className="form-control"
                id="regAdd"
                defaultValue={this.state.regAdd === "" ? "" : this.state.regAdd}
                placeholder="Enter Address"
              />
            </FormGroup>
            <FormGroup className="auth-form-group-custom mb-2">
              <i className="ri-lock-2-line auti-custom-input-icon"></i>
              <Label htmlFor="userpassword">GSTIN</Label>
              <AvField
                name="otp"
                //   value={this.state.password}
                type="text"
                defaultValue={this.state.gstin === "" ? "" : this.state.gstin}
                onChange={this.handleForm}
                className="form-control"
                id="gstin"
                placeholder="Enter GSTIN"
              />
            </FormGroup>
            {this.state.msgAlert ? (
              <Alert color="danger">Please fill all the details</Alert>
            ) : null}
          </AvForm>
        </Modal>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={avatar1}
              alt="Header Avatar"
            />

            {/* <img
              className="rounded-circle imgbl header-profile-user mr-1 "
              src={avatar1}
              alt="Header Avatar"
              onMouseOver={this.hover()}
              onMouseOut={this.unhover()}
              id="power"
            />
            <img
              className="rounded-circle img-yl header-profile-user mr-1"
              src={avatar2}
              alt="Header Avatar2"
            />
             */}
            {/* <img
              className="rounded-circle img-yl header-profile-user mr-1"
              src={require("../../../assets/images/shutdown1.png")}
              onMouseOver={() =>
                (this.src = require("../../../assets/images/shutdown1.png"))
              }
              onMouseOut={() =>
                (this.src = require("../../../assets/images/shutdown2.png"))
              }
            /> */}

            {/* <img src={avatar1} class="img-top" alt="Card Front" /> */}
            {/* <Button color="danger">danger</Button>{" "} */}
            {/* <span className="d-none d-xl-inline-block ml-1 text-transform">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i> */}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href="#" onClick={this.showModal}>
              <i className="ri-user-line align-middle mr-1"></i>{" "}
              {this.props.t("Profile")}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                this.fetchInvoice();
              }}
              href="#"
            >
              <i className="ri-wallet-2-line align-middle mr-1"></i>{" "}
              {this.props.t("Request Invoice")}
            </DropdownItem>
            {/* <DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}</DropdownItem> */}
            {/* <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}</DropdownItem> */}
            {/* <DropdownItem divider /> */}
            <DropdownItem onClick={this.handleLogout} className="text-danger">
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
