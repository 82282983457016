import React from "react";
import { message, notification, Modal } from "antd";
import CryptoJS from "crypto-js";
import API from "../../config/config";
import Authbg from "../../assets/images/authgif.gif";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardSubtitle,
  CardBody,
} from "reactstrap";

// Redux
import { Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import signangel from "../../assets/images/signangel.png";

// actions

// import images
import logodark from "../../assets/images/logo-dark.png";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export default function LoginDel() {
  let history = useHistory();
  const [username, setUsername] = React.useState("");
  const [userpassword, setUserpassword] = React.useState("");
  const [coupon, setCoupon] = React.useState("");
  const [plan, setPlan] = React.useState("standard");
  const [userToken, setUserToken] = React.useState("");
  const [isModalVisiblePay, setIsModalVisiblePay] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);

  const handlePlanSelection = (planid) => {
    let elea = document.getElementById("payCla");
    let eleb = document.getElementById("payClb");

    if (planid === "payCla") {
      setPlan("standard");

      eleb.style.boxShadow = "none";
      elea.style.boxShadow =
        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)";
    } else if (planid === "payClb") {
      setPlan("basic");
      elea.style.boxShadow = "none";
      eleb.style.boxShadow =
        "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) ";
    }
  };

  const showModalpay = () => {
    setIsModalVisiblePay(true);
  };

  const handleOkpay = () => {
    handleSubmitPlan();
    setIsModalVisiblePay(false);
  };

  const handleCancelpay = () => {
    setIsModalVisiblePay(false);
  };

  function handleSubmitPlan() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: userToken,
      },
      body: JSON.stringify({
        coupon: coupon,
        plan: plan,
      }),
    };
    fetch(API.api_route + "/v1/payment/init/", requestOptions)
      .then((response) => {
        if (response.status === 201) {
          response.json().then((res) => {
            displayRazorPay(res.data.id);
          });
        } else {
          notification.error({ message: "Error Occurred" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function success(msg) {
    Modal.success({
      content: msg,
    });
  }

  function error(msg) {
    Modal.error({
      title: "Failure",
      content: msg,
    });
  }

  async function displayRazorPay(id) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    let pln = plan.split("")[0].toUpperCase() + plan.slice(1);
    var options = {
      key: "rzp_live_ViCVdzYBLByIoH", // Enter the Key ID generated from the Dashboard
      amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Signage Animator",
      description: `${pln}`,
      prefill: {
        email: username,
      },
      image:
        "https://www.signageanimator.com/assets/img/bitbang-black-logo.png",
      // subscription_id: `${id}`,
      order_id: `${id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: userToken,
          },
          body: JSON.stringify({
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          }),
        };
        fetch(API.api_route + "/v1/payment/redirect/", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              success("Last Transaction was successfull");
            } else {
              error("Last Transaction was interrupted");
            }
          })
          .catch((err) => {
            console.log(err);
            notification.error({ message: "ServerError" });
          });
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#ECD002",
      },
    };
    var paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      error("Last Transaction was interrupted");
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    paymentObject.open();
  }

  function handleSubmit() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username.toLowerCase(),
        password: CryptoJS.MD5(userpassword).toString(),
      }),
    };
    fetch(API.api_route + "/v1/user/login/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "success") {
          if (data.level !== "") {
            message.success("Logged in successfully !");
            setLoginError(false);
            localStorage.setItem("token", data.token);
            localStorage.setItem("level", data.level);
            history.push("dashboard");
          } else {
            message.success("Logged in successfully !");
            setLoginError(false);
            localStorage.setItem("token", data.token);
            localStorage.setItem("level", data.level);
            history.push("dashboard");
            // setUserToken(data.token);
            // showModalpay();
          }
        } else {
          setLoginError(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  var handleUsername = (e) => {
    setUsername(e.target.value);
  };

  var handlePassword = (e) => {
    setUserpassword(e.target.value);
  };

  return (
    <div>
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <Modal
                              title="Plans"
                              visible={isModalVisiblePay}
                              onOk={handleOkpay}
                              onCancel={handleCancelpay}
                            >
                              <CardDeck>
                                <Card
                                  onClick={() => {
                                    handlePlanSelection("payClb");
                                  }}
                                  className="payCl"
                                  id="payClb"
                                >
                                  {/* <CardImg
                                    top
                                    width="100%"
                                    src="/assets/256x186.svg"
                                    alt="Card image cap"
                                  /> */}
                                  <CardBody>
                                    <CardTitle tag="h3">Basic</CardTitle>
                                    <CardTitle tag="h3">
                                      {" "}
                                      &#8377; 2499
                                    </CardTitle>
                                    {/* <CardSubtitle
                                      tag="h6"
                                      className="mb-2 text-muted"
                                    >
                                      Card subtitle
                                    </CardSubtitle> */}
                                    <CardText>6 Months</CardText>
                                  </CardBody>
                                </Card>

                                <Card
                                  onClick={() => {
                                    handlePlanSelection("payCla");
                                  }}
                                  className="payCl"
                                  id="payCla"
                                >
                                  {/* <CardImg
                                    top
                                    width="100%"
                                    src="/assets/256x186.svg"
                                    alt="Card image cap"
                                  /> */}
                                  <CardBody>
                                    <CardTitle tag="h3">Standard</CardTitle>
                                    <CardTitle tag="h3">&#8377; 3999</CardTitle>
                                    {/* <CardSubtitle
                                      tag="h6"
                                      className="mb-2 text-muted"
                                    >
                                      Card subtitle
                                    </CardSubtitle> */}
                                    <CardText>12 Months</CardText>
                                  </CardBody>
                                </Card>
                              </CardDeck>

                              <AvForm className="form-horizontal mt-2">
                                <FormGroup className="auth-form-group-custom mb-1">
                                  <i className="ri-user-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="username">Coupon code</Label>
                                  <AvField
                                    name="coupon"
                                    //   value={this.state.username}
                                    type="text"
                                    onChange={(e) => {
                                      setCoupon(e.target.value);
                                    }}
                                    className="form-control"
                                    id="coupon"
                                    placeholder="Do you have a coupon code"
                                  />
                                </FormGroup>
                              </AvForm>
                            </Modal>

                            <div>
                              <Link to="/" className="logo">
                                <img
                                  src={signangel}
                                  className="img-fluid"
                                  // style={{ height: "50px", marginTop: "40px" }}
                                  alt="logo"
                                />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Create your own Animations
                            </p>
                          </div>

                          {loginError ? (
                            <Alert color="danger">Invalid Email/Password</Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm className="form-horizontal">
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Email</Label>
                                <AvField
                                  name="username"
                                  //   value={this.state.username}
                                  type="text"
                                  onChange={handleUsername}
                                  className="form-control"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter Email"
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  //   value={this.state.password}
                                  type="password"
                                  onChange={handlePassword}
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </FormGroup>

                              <div className="mt-4 text-center">
                                <Button
                                  onClick={handleSubmit}
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>

                              <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-1 text-center">
                            <p>
                              Don't have an account ?{" "}
                              <Link
                                to="/register"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Register{" "}
                              </Link>{" "}
                            </p>
                            <a
                              href="https://evrenglobalsolutions.com/"
                              target="_blank"
                            >
                              <p>
                                © 2021 EGS. Crafted with{" "}
                                <i className="mdi mdi-heart text-danger"></i> by
                                EGS
                              </p>
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col style={{ background: "black" }} lg={8}>
                {/* <div className="authentication-bg">
                  <div className="bg-overlay">
                    
                  </div>
                </div> */}
                <img
                  src={Authbg}
                  style={{ width: "100%" }}
                  className="authimg img-fluid"
                  alt="authbg"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}
// className="bg-overlay"
