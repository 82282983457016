import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function Curtain(props) {
  let anim = () => {
    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxCur span");
    // if (checkSpan.length === 0) {
    //   for (var i = 0; i < wordArr.length; i++) {
    //     let span = document.createElement("span");
    //     span.id = `cur${i}`;
    //     span.innerHTML = wordArr[i];
    //     document.getElementById("wordBoxCur").appendChild(span);
    //   }
    // }
    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `curdiv${count}`;

      document.getElementById("wordBoxCur").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#curdiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });

          count++;
          var div = document.createElement("div");
          div.id = `curdiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxCur").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `cur${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`cur${i}`, props.wordArray[count].textColor);
          document.getElementById(`curdiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxCur span");

    // allSpan.forEach((i) => {
    //   i.style.fontSize = "66px";
    //   i.style.color = "white";
    //   i.style.margin = "3px";
    // });

    var varTime = 10;

    let forwardAnimation = (start, end, color) => {
      for (let i = parseInt(start); i <= parseInt(end); i++) {
        let ds = document.getElementById(`cur${i}`);
        if (ds !== null) {
          setTimeout(() => {
            ds.style.color =
              color === "white" ? "white" : `${map1.get(`cur${i}`)}`;
            ds.classList.add("glow");

            if (color !== "white") {
              // ds.classList.add("tso");
              // ds.classList.remove("tsn");
              ds.style.textShadow = `0px 0px 50px ${map1.get(
                `cur${i}`
              )}, 0px 0px 50px ${map1.get(`cur${i}`)}`;
            } else {
              ds.style.textShadow = "none";
            }
          }, varTime * 10 * i);
        }
      }
    };

    let backwardAnimation = (start, end, color) => {
      for (let j = parseInt(start); j >= parseInt(end); j--) {
        let ds = document.getElementById(`cur${j}`);
        if (ds !== null) {
          setTimeout(() => {
            ds.style.color =
              color === "white" ? "white" : `${map1.get(`cur${j}`)}`;
            ds.classList.add("glow");

            if (color !== "white") {
              // ds.classList.add("tso");
              // ds.classList.remove("tsn");
              ds.style.textShadow = `0px 0px 50px ${map1.get(
                `cur${j}`
              )}, 0px 0px 50px ${map1.get(`cur${j}`)}`;
            } else {
              ds.style.textShadow = "none";
            }

            //   console.log(j, ds.innerHTML);
          }, varTime * 10 * (wordArr.length - 1 - j));
        }
        // console.log(j);
      }
    };

    forwardAnimation(0, (wordArr.length - 1) / 2, "yellow");
    backwardAnimation(wordArr.length - 1, wordArr.length / 2, "yellow");
    setTimeout(() => {
      backwardAnimation((wordArr.length - 1) / 2, 0, "white");
      forwardAnimation(
        parseInt(wordArr.length / 2),
        wordArr.length - 1,
        "white"
      );
    }, varTime * 10);

    let t = setInterval(() => {
      forwardAnimation(0, (wordArr.length - 1) / 2, "yellow");
      backwardAnimation(wordArr.length - 1, wordArr.length / 2, "yellow");
      setTimeout(() => {
        backwardAnimation((wordArr.length - 1) / 2, 0, "white");
        forwardAnimation(
          parseInt(wordArr.length / 2),
          wordArr.length - 1,
          "white"
        );
      }, varTime * 10);
    }, wordArr.length * (varTime + 10) * 10);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxCur"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default Curtain;
