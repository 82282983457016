import React, { useState, useEffect } from "react";
// import style from './Animation1.module.css';
import styled, { keyframes } from "styled-components";
import { Animate } from "../styledanimation/StyledAnimation";
const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

let animateFunc = (color) => {
  const animate = keyframes`
0%,80%{
  opacity:1;
  color: ${color};
  text-shadow: 0 0 20px #fff, 0 0 30px ${color}, 0 0 40px ${color}, 0 0 50px ${color}, 0 0 60px ${color}, 0 0 70px ${color}, 0 0 80px ${color};
    
}

100%{
    color: #fff;
    
    text-shadow: none;
    opacity:0.2;}
`;

  return animate;
};
// one word at a time

export default function Animation5(props) {
  const [toggle, settoggle] = useState(false);
  // const text = props.textVal.split("");
  const text = ["a", "b", "c", "d", "e"];
  // let len = text.length;
  // let t = -0.2;
  // const addval = (1 / len).toFixed(3);
  const letters = text.map((letter) => {
    // t = t + (0.2);
    return (
      <Animate delay={"none"} animate={animateFunc}>
        {letter}
      </Animate>
    );
  });
  useEffect(() => {
    settoggle(!toggle);
  }, [props.textVal]);

  return (
    <Heading color={props.color}>
      {props.wordArray.map((itm) => (
        <div style={{ fontSize: `${itm.textSize}` }}>
          <Animate
            delay={"none"}
            animate={animateFunc(itm.textColor)}
            animteTiming="1s"
            timingFunction={"linear"}
            animationDirection={false}
          >
            {itm.text}
          </Animate>
        </div>
      ))}
    </Heading>
  );
}
