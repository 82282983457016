import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../../store/actions";

//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {this.props.type !== "condensed" ? (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarContent
                  setBackgroundColor={this.props.setBackgroundColor}
                  setFontColor={this.props.setFontColor}
                  setFonttype={this.props.setFonttype}
                  backgroundColor={this.props.backgroundColor}
                  fontColor={this.props.fontColor}
                  handleFontFamily={this.props.handleFontFamily}
                  fonttype={this.props.fonttype}
                  selectedFile={this.props.selectedFile}
                  setSelectedFile={this.props.setSelectedFile}
                  text={this.props.text}
                  setBackgroundImage={this.props.setBackgroundImage}
                />
              </SimpleBar>
            ) : (
              <SidebarContent
                setBackgroundColor={this.props.setBackgroundColor}
                setFontColor={this.props.setFontColor}
                setFonttype={this.props.setFonttype}
                backgroundColor={this.props.backgroundColor}
                setBackgroundImage={this.props.setBackgroundImage}
                handleFontFamily={this.props.handleFontFamily}
                fontColor={this.props.fontColor}
                fonttype={this.props.fonttype}
                selectedFile={this.props.selectedFile}
                setSelectedFile={this.props.setSelectedFile}
                text={this.props.text}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
