import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { useLocation, useParams } from "react-router-dom";

// const { id } = useParams();

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        !localStorage.getItem("token") &&
        props.match.path === "/watch/:id" &&
        props.match.params.id
      ) {
        console.log("does Nothing");
      } else if (isAuthProtected && !localStorage.getItem("token")) {
        return <Redirect to={{ pathname: "/login" }} />;
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
