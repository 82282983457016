import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import "./animats.css";

function SeqOn(props) {
  let anim = () => {
    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxSeq span");
    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `seqOndiv${count}`;

      document.getElementById("wordBoxSeq").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#seqOndiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });

          count++;
          var div = document.createElement("div");
          div.id = `seqOndiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxSeq").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `seqOn${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`seqOn${i}`, props.wordArray[count].textColor);
          document.getElementById(`seqOndiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxSeq span");

    var glowDimTime = 20;
    var repeatTime = glowDimTime + 10;

    let forwardAnimation = () => {
      for (let i = 0; i < wordArr.length; i++) {
        let ds = document.getElementById(`seqOn${i}`);
        if (ds !== null) {
          setTimeout(() => {
            ds.style.color = `${map1.get(`seqOn${i}`)}`;
            ds.classList.add("glow");
            // ds.classList.add("tso");
            // ds.classList.remove("tsn");
            ds.style.textShadow = `0px 0px 50px ${map1.get(
              `seqOn${i}`
            )}, 0px 0px 50px ${map1.get(`seqOn${i}`)}`;
          }, glowDimTime * 10 * i);
        }
      }
    };

    let animate = () => {
      forwardAnimation();
      setTimeout(() => {
        allSpan.forEach((i) => {
          i.style.color = "white";
          // i.classList.add("tsn");
          i.style.textShadow = "none";
        });
      }, wordArr.length * glowDimTime * 10);
    };

    animate();

    let t = setInterval(() => {
      animate();
    }, repeatTime * 10 * wordArr.length);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div style={{ fontFamily: "ui-sans-serif" }} id="wordBoxSeq"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default SeqOn;
