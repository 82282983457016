import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function Onebyone(props) {
  // let anim = () => {
  //   // let y = props.word;
  //   // let wordArr = y.split("");
  //   // let checkSpan = document.querySelectorAll("span");
  //   // if (checkSpan.length === 0) {
  //   //   for (var i = 0; i < wordArr.length; i++) {
  //   //     let span = document.createElement("span");
  //   //     span.id = `one${i}`;
  //   //     span.innerHTML = wordArr[i];
  //   //     document.getElementById("wordBoxOne").appendChild(span);
  //   //   }
  //   // }

  //   // let allSpan = document.querySelectorAll("span");

  //   // allSpan.forEach((i) => {
  //   //   i.style.fontSize = "66px";
  //   //   i.style.color = "yellow";
  //   //   i.classList.add("glow");

  //   //   i.classList.add("tso");
  //   //   i.style.margin = "3px";
  //   // });

  //   console.log(props.wordArray);

  //   let y = "";
  //   props.wordArray.map((itm) => {
  //     y += itm.text + "%";
  //   });
  //   let wordArr = y.split("");
  //   const map1 = new Map();
  //   let checkSpan = document.querySelectorAll("#wordBoxOne span");

  //   if (checkSpan.length === 0) {
  //     var count = 0;
  //     var div = document.createElement("div");

  //     // div.style.fontSize = props.wordArray[count].textSize;
  //     div.id = `onediv${count}`;

  //     document.getElementById("wordBoxOne").appendChild(div);

  //     for (var i = 0; i < wordArr.length; i++) {
  //       if (wordArr[i] === "%") {
  //         let sd = document.querySelectorAll(`#onediv${count} span`);
  //         sd.forEach((itm) => {
  //           itm.style.fontSize = props.wordArray[count].textSize;
  //         });

  //         let span = document.createElement("span");
  //         span.id = `one${i}`;
  //         span.style.fontSize = "1rem";
  //         span.style.color = "yellow";
  //         span.innerHTML = wordArr[i];
  //         span.style.display = "none";
  //         // console.log(document.getElementById(`div${count}`));
  //         map1.set(`one${i}`, props.wordArray[count].textColor);
  //         document.getElementById(`onediv${count}`).appendChild(span);

  //         count++;
  //         var div = document.createElement("div");
  //         div.id = `onediv${count}`;

  //         // div.style.fontSize = props.wordArray[count - 1].textSize;
  //         document.getElementById("wordBoxOne").appendChild(div);
  //       } else {
  //         let span = document.createElement("span");
  //         span.id = `one${i}`;
  //         span.style.fontSize = "1rem";
  //         span.style.color = "yellow";
  //         span.innerHTML = wordArr[i];
  //         // console.log(document.getElementById(`div${count}`));
  //         map1.set(`one${i}`, props.wordArray[count].textColor);
  //         document.getElementById(`onediv${count}`).appendChild(span);
  //       }
  //     }
  //   }

  //   let allSpan = document.querySelectorAll("#wordBoxOne span");

  //   var varTime = 5;

  //   function glow(ele) {
  //     ele.style.color = "yellow";
  //     ele.classList.add("tso");
  //     ele.classList.remove("tsn");
  //   }

  //   function dim(ele) {
  //     ele.style.color = "white";
  //     ele.classList.add("tsn");
  //     ele.classList.remove("tso");
  //   }

  //   let twobytwo = () => {
  //     for (let k = 0; k < wordArr.length; k++) {
  //       setTimeout(() => {
  //         let ele = document.getElementById(`one${k}`);
  //         if (ele !== null) {
  //           dim(ele);
  //           setTimeout(() => {
  //             glow(ele);
  //           }, 100 * varTime);
  //         }
  //       }, k * 100 * varTime);
  //     }
  //   };

  //   twobytwo();
  //   setInterval(() => {
  //     twobytwo();
  //   }, wordArr.length * varTime * 10);
  // };

  let anim = () => {
    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxOne span");

    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `onediv${count}`;

      document.getElementById("wordBoxOne").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#onediv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });

          let span = document.createElement("span");
          span.id = `one${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          span.style.display = "none";
          // console.log(document.getElementById(`div${count}`));
          map1.set(`one${i}`, props.wordArray[count].textColor);
          document.getElementById(`onediv${count}`).appendChild(span);

          count++;
          var div = document.createElement("div");
          div.id = `onediv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxOne").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `one${i}`;
          span.style.fontSize = "1rem";
          span.style.color = props.wordArray[count].textColor;
          span.style.textShadow = `0px 0px 50px ${props.wordArray[count].textColor}, 0px 0px 50px ${props.wordArray[count].textColor}`;
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`one${i}`, props.wordArray[count].textColor);
          document.getElementById(`onediv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxOne span");

    var varTime = 3;

    function glow(ele, id) {
      ele.style.color = map1.get(id);
      ele.style.textShadow = `0px 0px 50px ${map1.get(
        id
      )}, 0px 0px 50px ${map1.get(id)}`;
    }

    function dim(ele) {
      ele.style.color = "white";
      ele.style.textShadow = "none";
    }

    let twobytwo = () => {
      for (let k = 0; k < wordArr.length; k++) {
        setTimeout(() => {
          let ele = document.getElementById(`one${k}`);
          if (ele !== null) {
            dim(ele);
            setTimeout(() => {
              glow(ele, `one${k}`);
            }, 100 * varTime);
          }
        }, k * 100 * varTime);
      }
    };

    twobytwo();
    let t = setInterval(() => {
      twobytwo();
    }, wordArr.length * varTime * 100);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxOne"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default Onebyone;
