import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Container,
  CustomInput,
  Button,
  Form,
} from "reactstrap";
import { useLocation, useParams } from "react-router-dom";

import styled from "styled-components";
import { message, notification, Modal } from "antd";
import SandGlass from "../animats/sandGlass";
import SeqOn from "../animats/seqOn";
import Curtain from "../animats/curtain";
import Curtain2 from "../animats/curtain2";
import CrossEffect from "../animats/crossEffect";
import Twobytwo from "../animats/twobytwo";
import Threebythree from "../animats/threebythree";
import Wave1 from "../animats/wave1";
import Animation6 from "../animations/Animation6/Animation6";
import Animation5 from "../animations/Animation5/Animation5";
import Animation10 from "../animations/Animation10/Animation10";
import Onebyone from "../animats/onebyone";
import InitialText from "../animats/initialText";
import "./Textbox.module.css";
import API from "../../../config/config";

function ViewOnlyTextBox() {
  const [animateSignal, setAnimateSignal] = useState(false);
  const [timerForAuto, setTimerForAuto] = useState();
  const [animationIndex, setAnimationIndex] = useState(-1);
  const [speed, setSpeed] = useState(20);
  const [iniText, setInitext] = useState("");
  const [iniTextforNewLine, setInitextforNewLine] = useState("");
  const [iniTextArray, setIniTextArray] = useState([]);
  const [count, setCount] = useState(0);
  const [fontSize, setFontSize] = useState(2);
  const [animCre, setAnimCre] = useState(false);
  const [fontStyle, setFontStyle] = useState("Verdana");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shareId, setShareId] = useState("");
  const [shareRecId, setShareRecId] = useState("");
  const [viewOnly, setViewOnly] = useState(false);
  const [textCreated, setTextCreated] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("black");
  const { id } = useParams();
  useEffect(() => {
    if (id !== undefined && id !== "" && id !== null) {
      setViewOnly(true);
      getAimationById();
    }
  }, []);

  let handleText = () => {
    setTextCreated(true);
  };

  let getAimationById = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
    };

    fetch(API.api_route + `/v1/share/?token=${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          let red = data.data;

          setIniTextArray(red.slice(0, -1));
          // setShareRecId(id);
          setAnimCre(true);
          handleText();
          let animaVal = red.splice(-1)[0];
          setBackgroundColor(animaVal.backgroundColor);
          setAnimationIndex(animaVal.animationIndex);
        } else {
          if (data.reason === "UnAuthorized Access") {
            notification.error({ message: data.reason });
            localStorage.removeItem("token");
            window.location.reload();
          }
        }
      })
      .catch((e) => {
        notification.error({ message: "Error" });
        console.log(e);
      });
  };
  return (
    <React.Fragment>
      <Container
        fluid
        style={{
          position: "absolute",
          top: "47%",
          left: "47%",
          transform: "translate(-47%, -47%)",
        }}
      >
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <div
                  className="divWrap"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "auto",
                    minHeight: "300px",
                    width: "auto",
                    background: backgroundColor,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    alignItems: "center",
                  }}
                >
                  {textCreated && (
                    <div
                      className="main"
                      style={{ textAlign: "center", overflow: "hidden" }}
                    >
                      {animationIndex === -1 && (
                        <div>
                          {iniTextArray.map((wrd) => {
                            return (
                              <div
                                id={`div${wrd.count}`}
                                style={{ color: "white" }}
                              >
                                {wrd.text}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {animationIndex === 0 && (
                        <SeqOn wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 1 && (
                        <Curtain wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 2 && (
                        <Curtain2 wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 3 && (
                        <CrossEffect wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 4 && (
                        <Twobytwo wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 5 && (
                        <Threebythree wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 6 && (
                        <Wave1 wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 7 && (
                        <SandGlass wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 8 && (
                        <Animation6 wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 9 && (
                        <Animation5 wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 10 && (
                        <Onebyone wordArray={iniTextArray} speed={speed} />
                      )}
                      {animationIndex === 11 && (
                        <Animation10 wordArray={iniTextArray} speed={speed} />
                      )}
                    </div>
                  )}
                </div>
                {/* </ReactWaterMark> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ViewOnlyTextBox;
