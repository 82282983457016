import React, { useState, useEffect } from "react";
import Table from "../../Tables/ResponsiveTables";
import ReactWaterMark from "react-watermark-component";

import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  Alert,
  FormGroup,
  Container,
  CustomInput,
  Button,
  Form,
} from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import "./textbox.css";
import styled from "styled-components";
import { message, notification, Modal } from "antd";
import SandGlass from "../animats/sandGlass";
import SeqOn from "../animats/seqOn";
import Curtain from "../animats/curtain";
import Curtain2 from "../animats/curtain2";
import CrossEffect from "../animats/crossEffect";
import Twobytwo from "../animats/twobytwo";
import Threebythree from "../animats/threebythree";
import Wave1 from "../animats/wave1";
import Animation6 from "../animations/Animation6/Animation6";
import Animation5 from "../animations/Animation5/Animation5";
import Animation10 from "../animations/Animation10/Animation10";
import Onebyone from "../animats/onebyone";
import InitialText from "../animats/initialText";
import "./Textbox.module.css";
import API from "../../../config/config";

export default function Textbox(props) {
  const [switchanimation, setSwitchAnimation] = useState({
    A: 8,
    name: "nischay 1",
  });
  const [animateSignal, setAnimateSignal] = useState(false);
  const [timerForAuto, setTimerForAuto] = useState();
  const [animationIndex, setAnimationIndex] = useState(-1);
  const [speed, setSpeed] = useState(20);
  const [iniText, setInitext] = useState("");
  const [iniTextforNewLine, setInitextforNewLine] = useState("");
  const [iniTextArray, setIniTextArray] = useState([]);
  const [count, setCount] = useState(0);
  const [fontSize, setFontSize] = useState(2);
  const [animCre, setAnimCre] = useState(false);
  const [fontStyle, setFontStyle] = useState("Verdana");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shareId, setShareId] = useState("");
  const [shareRecId, setShareRecId] = useState("");
  const [viewOnly, setViewOnly] = useState(false);
  const [custAniname, setCustAniname] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [isMobile, setIsMobile] = useState(false);
  const [isModalVisiblefortc, setIsModalVisibletc] = useState(false);
  const [isModalVisibleforpp, setIsModalVisiblepp] = useState(false);
  const [inputB, setInputB] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [textFault, setTextFault] = useState(true);
  const [addedText, setAddedText] = useState([]);
  const [alertmsg, setAlertmsg] = useState("Please add text above");
  const [clipAlert, setClipAlert] = useState(false);

  const showClip = () => {
    setClipAlert(true);
    setTimeout(() => {
      setClipAlert(false);
    }, 2000);
  };

  const chkAuth = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
    };

    fetch(API.api_route + "/v1/ping", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.status);
        if (data.status === "failed") {
          if (data.reason === "UnAuthorized Access") {
            notification.error({ message: data.reason });
            localStorage.removeItem("token");
            window.location.reload();
          }
        }
      })
      .catch((e) => {
        notification.error({ message: "UnAuthorized Access" });
        console.log(e);
      });
  };

  const chkPrevText = () => {
    return addedText[addedText.length - 1] !== `newline${inputB.length - 1}`;
  };

  const onShowAlert = () => {
    setShowAlert(true);
    (() => {
      window.setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    })();
  };

  const handleNextLine = () => {
    setInputB((inputB) => [...inputB, 1]);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModaltc = () => {
    setIsModalVisibletc(true);
  };

  const handleOktc = () => {
    setIsModalVisibletc(false);
  };

  const handleCanceltc = () => {
    setIsModalVisibletc(false);
  };

  const showModalpp = () => {
    setIsModalVisiblepp(true);
  };

  const handleOkpp = () => {
    setIsModalVisiblepp(false);
  };

  const handleCancelpp = () => {
    setIsModalVisiblepp(false);
  };

  const handleSharing = () => {
    showModal();
    let share = iniTextArray;

    let sh = {
      animationIndex: animationIndex,
      backgroundColor: backgroundColor,
      custAniname: custAniname === "" ? Date.now() : custAniname,
    };
    share.push(sh);
    // console.log(JSON.stringify(share));
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(share),
    };

    fetch(API.api_route + "/v1/share/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.status);
        if (data.status === undefined && data.id) {
          setShareId(data.id);
          iniTextArray.splice(-1);
        } else if (data.status === "failed") {
          if (data.reason === "UnAuthorized Access") {
            notification.error({ message: data.reason });
            localStorage.removeItem("token");
            window.location.reload();
          }
        }
      })
      .catch((e) => {
        notification.error({ message: "Error" });
        console.log(e);
      });
  };

  let refresh = () => {
    setAnimationIndex(-1);
    setInitext("");
    setInitextforNewLine("");
    setIniTextArray([]);
    setInputB([]);
    setCount(0);
    setAnimCre(false);
    props.notHandleText();
    props.headAnimationIndex(-1);
    setAddedText([]);
    setTextFault(true);
    if (!animCre) {
      document.getElementById("inputfirst").value = "";
    }
  };

  let handleTextc = () => {
    let t = {
      text: iniTextforNewLine,
      textSize: "2vw",
      textColor: "yellow",
      textStyle: "Verdana",
      count: count,
    };
    setCount(count + 1);
    setInitext(iniText + "%");
    setIniTextArray((iniTextArray) => [...iniTextArray, t]);
    setInitextforNewLine("");
    // console.log(iniTextArray);
  };

  let handleFontSize = (itm, val) => {
    // console.log(iniTextArray.indexOf(itm));
    let nar = iniTextArray;
    nar[nar.indexOf(itm)].textSize = val + "vw";
    setIniTextArray(nar);
    // console.log(document.getElementById(`div${itm.count}`));
    document.getElementById(`div${itm.count}`).style.fontSize = val + "vw";
  };

  let handleFontStyle = (itm, val) => {
    // console.log(iniTextArray.indexOf(itm));
    let nar = iniTextArray;
    nar[nar.indexOf(itm)].textStyle = val;
    setIniTextArray(nar);
    // console.log(document.getElementById(`div${itm.count}`));
    document.getElementById(`div${itm.count}`).style.fontFamily = val;
    // setFontSize(e.target.value + "vh");
  };

  let handleFontColor = (itm, val) => {
    // console.log(itm, val);
    let nar = iniTextArray;
    nar[nar.indexOf(itm)].textColor = val;
    setIniTextArray(nar);
    // console.log(document.getElementById(`div${itm.count}`));
    document.getElementById(`div${itm.count}`).style.color = val;
    // setFontSize(e.target.value + "vh");
  };

  let handleNewLine = () => {
    let t = {
      text: iniTextforNewLine,
      textSize: "16px",
      textColor: "white",
    };
    setInitext(iniText + "%");
    setIniTextArray((iniTextArray) => [...iniTextArray, t]);
    setInitextforNewLine("");
  };

  let handleAnimationIndex = () => {
    // console.log(iniTextArray);
    setAnimationIndex((animationIndex + 1) % 12);
    props.headAnimationIndex((animationIndex + 1) % 12);
  };

  let handleAnimationIndexPrev = () => {
    if (animationIndex === 0) {
      setAnimationIndex(11);
      props.headAnimationIndex(11);
    } else {
      setAnimationIndex(animationIndex - 1);
      props.headAnimationIndex(animationIndex - 1);
    }
  };

  var sectionStyle = {
    width: "100%",
    height: "200px",
    backgroundImage: `url(${props.selectedFile})`,
    // zIndex: 1000
    fontFamily: props.fonttype,
    color: props.fontColor,
    backgroundColor: props.backgroundColor,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  };
  const { id } = useParams();
  // const location = useLocation();
  useEffect(() => {
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  // let getAimationById = () => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       token: `${localStorage.getItem("token")}`,
  //     },
  //   };

  //   fetch(API.api_route + `/v1/share/?token=${id}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       if (data.status === "success") {
  //         let red = data.data;

  //         setIniTextArray(red.slice(0, -1));
  //         // setShareRecId(id);
  //         setAnimCre(true);
  //         props.handleText();
  //         setAnimationIndex(red.splice(-1)[0].animationIndex);
  //       } else {
  //         if (data.reason === "UnAuthorized Access") {
  //           notification.error({ message: data.reason });
  //           localStorage.removeItem("token");
  //           window.location.reload();
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       notification.error({ message: "Error" });
  //       console.log(e);
  //     });
  // };

  const text = `Signage Animation`;
  const beginAlarm = function () {
    console.log("start alarm");
    notification.error({ message: "Kindly pay to remove watermark" });
  };
  const options = {
    chunkWidth: 200,
    chunkHeight: 60,
    textAlign: "left",
    textBaseline: "bottom",
    globalAlpha: 0.17,
    font: "20px Microsoft Yahei",
    rotateAngle: -0.26,
    fillStyle: "#fff",
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Modal
          title="Share Link"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div>
              <p>https://creator.signageanimator.com/watch/{shareId}</p>
            </div>
            <div>
              <Button
                // color="primary "
                type="button"
                color="success"
                onClick={() => {
                  {
                    navigator.clipboard.writeText(
                      `https://creator.signageanimator.com/watch/${shareId}`
                    );
                  }
                  showClip();
                }}
              >
                copy
              </Button>
            </div>{" "}
          </div>
          <div className="mt-2">
            {clipAlert ? <Alert color="success">Copied!</Alert> : null}
          </div>
        </Modal>

        <Row>
          <Col xs={12}>
            <Card>
              <CardBody
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <div
                  className="divWrap"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "auto",
                    minHeight: "300px",
                    width: "auto",
                    background: `${backgroundColor}`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    alignItems: "center",
                    overflow: "hidden",

                    // fontFamily: `${fontStyle}`,
                  }}
                >
                  {props.textCreated ? (
                    <div className="main">
                      {animationIndex === -1 && (
                        <div>
                          {iniTextArray.map((wrd) => {
                            return (
                              <div
                                id={`div${wrd.count}`}
                                style={{ color: "white" }}
                              >
                                {wrd.text}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {animationIndex === 0 && (
                        <SeqOn
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 1 && (
                        <Curtain
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 2 && (
                        <Curtain2
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 3 && (
                        <CrossEffect
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 4 && (
                        <Twobytwo
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 5 && (
                        <Threebythree
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 6 && (
                        <Wave1
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 7 && (
                        <SandGlass
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 8 && (
                        <Animation6
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 9 && (
                        <Animation5
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 10 && (
                        <Onebyone
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                      {animationIndex === 11 && (
                        <Animation10
                          word={props.textVal}
                          wordArray={iniTextArray}
                          speed={speed}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="main">
                      <h1>Enter Text</h1>
                    </div>
                  )}
                </div>
                {/* </ReactWaterMark> */}
                <div className="animGear">
                  <div className="mt-2">
                    <Form inline>
                      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="exampleEmail" className="mr-sm-1">
                          Background Color
                        </Label>

                        <Input
                          className="form-control"
                          type="color"
                          defaultValue="black"
                          onChange={(e) => setBackgroundColor(e.target.value)}
                          id="example-color-input"
                          style={{ width: "50px" }}
                        />
                      </FormGroup>
                    </Form>
                  </div>
                  {!animCre ? (
                    <div className="mt-2 ">
                      <Form inline>
                        <button
                          // color="primary "
                          type="button"
                          className=" btnM"
                          disabled={!props.textCreated}
                          style={{
                            display: !props.textCreated ? "none" : "block",
                          }}
                          onClick={() => {
                            chkAuth();

                            setAnimCre(true);
                            setAnimationIndex(animationIndex + 1);
                            props.headAnimationIndex(animationIndex + 1);
                          }}
                        >
                          Animate
                        </button>
                      </Form>
                    </div>
                  ) : (
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Label for="exampleEmail" className="mt-2">
                        Select Animation
                      </Label>
                      <button
                        // style={{ marginLeft: "5vh" }}
                        color="primary"
                        type="button"
                        className="waves-effect waves-light btnpn"
                        onClick={() => {
                          handleAnimationIndexPrev();
                        }}
                      >
                        <i className="mdi mdi-arrow-left"></i>
                      </button>{" "}
                      <button
                        // style={{ marginLeft: "5vh" }}
                        color="primary"
                        type="button"
                        className="waves-effect waves-light btnpn"
                        onClick={() => {
                          handleAnimationIndex();
                        }}
                      >
                        <i className="mdi mdi-arrow-right"></i>
                      </button>{" "}
                      <select
                        onChange={(e) => {
                          setAnimationIndex(parseInt(e.target.value));
                          props.headAnimationIndex(parseInt(e.target.value));
                        }}
                        style={{ width: "30%" }}
                        className="form-control"
                      >
                        <option value="none" selected disabled hidden>
                          Select an Option
                        </option>
                        <option value="0">Sequential On</option>
                        <option value="1">Curtain</option>
                        <option value="2">Curtain 2</option>
                        <option value="3">Cross Effect</option>
                        <option value="4">Two by Two</option>
                        <option value="5">Three by Three</option>
                        <option value="6">Wave 1</option>
                        <option value="7">Sand Glass</option>
                        <option value="8">Blinking</option>
                        <option value="9">Glow Dim</option>
                        <option value="10">One by One</option>
                        <option value="11">Wave3</option>
                      </select>
                    </div>
                  )}

                  {/* {animCre && ()} */}
                  <div className="mt-2">
                    <button
                      color="primary"
                      type="button"
                      className="waves-effect waves-light btnpn"
                      onClick={() => {
                        refresh();
                      }}
                    >
                      Reset
                    </button>
                  </div>

                  {!viewOnly && animCre && props.textCreated && (
                    <div className="mt-2 ">
                      <Form inline>
                        <Button
                          color="warning"
                          type="button"
                          className="waves-effect waves-light"
                          // disabled={!props.textCreated}
                          onClick={() => {
                            handleSharing();
                          }}
                        >
                          Share
                        </Button>
                      </Form>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={6}>
            {!viewOnly && !animCre && (
              <Card>
                <CardBody>
                  <div
                    style={{
                      width: "auto",
                    }}
                  >
                    <FormGroup row>
                      <Label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Text
                      </Label>
                      <Col md={10}>
                        <div style={{ display: "flex" }}>
                          <Input
                            className="form-control"
                            type="text"
                            id="inputfirst"
                            disabled={addedText.includes("inputfirst")}
                            onChange={(e) => {
                              setInitext(e.target.value);
                              setInitextforNewLine(e.target.value);
                              props.setText(e.target.value);
                            }}
                          />
                          <button
                            color="primary"
                            style={{ fontSize: "16px", width: "19%" }}
                            type="button"
                            className="waves-effect ml-3 waves-light btnpn"
                            onClick={() => {
                              if (addedText.includes("inputfirst")) {
                                setAlertmsg("Please reset to edit");
                                onShowAlert();
                              } else if (iniText === "") {
                                setAlertmsg("Please add text above");
                                onShowAlert();
                                setTextFault(true);
                              } else {
                                // handleNewLine();
                                props.handleText();
                                handleTextc();
                                setAddedText((addedText) => [
                                  ...addedText,
                                  `inputfirst`,
                                ]);
                                setTextFault(false);
                              }
                            }}
                          >
                            {isMobile ? "+" : "Add ^"}
                          </button>{" "}
                        </div>
                      </Col>
                    </FormGroup>

                    {inputB.map((item, index) => {
                      return (
                        <FormGroup row>
                          <Label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                          >
                            New Line
                          </Label>
                          <Col md={10}>
                            <div style={{ display: "flex" }}>
                              <Input
                                className="form-control"
                                type="text"
                                id={`newline${index}`}
                                disabled={addedText.includes(`newline${index}`)}
                                onChange={(e) => {
                                  setInitext(e.target.value);
                                  setInitextforNewLine(e.target.value);
                                  props.setText(e.target.value);
                                }}
                              />
                              <button
                                color="primary"
                                style={{ fontSize: "16px", width: "19%" }}
                                type="button"
                                className="waves-effect ml-3 waves-light btnpn"
                                onClick={() => {
                                  if (addedText.includes(`newline${index}`)) {
                                    setAlertmsg("Please reset to edit");
                                    onShowAlert();
                                  } else if (
                                    document.getElementById(`newline${index}`)
                                      .value === ""
                                  ) {
                                    setAlertmsg("Please add text above");
                                    onShowAlert();
                                  } else {
                                    props.handleText();
                                    handleTextc();
                                    setAddedText((addedText) => [
                                      ...addedText,
                                      `newline${index}`,
                                    ]);
                                  }
                                }}
                              >
                                {isMobile ? "+" : "Add ^"}
                              </button>
                            </div>
                          </Col>
                        </FormGroup>
                      );
                    })}
                    {/* if (iniText === "") {
                                    notification.error({
                                      message: "Enter text first",
                                    });
                                  } else {
                                    // handleNewLine();
                                    props.handleText();
                                    handleTextc();
                                  } */}
                    <div className="mt-4" style={{ display: "flex" }}>
                      <button
                        color="primary"
                        type="button"
                        className="waves-effect waves-light btnpn"
                        onClick={() => {
                          if (
                            textFault ||
                            (chkPrevText() && inputB.length !== 0)
                          ) {
                            setAlertmsg("Please add the text above");
                            onShowAlert();
                          } else {
                            handleNextLine();
                          }
                        }}
                      >
                        Next line
                      </button>{" "}
                      {showAlert && (
                        <Alert className="ml-2" color="danger">
                          {alertmsg}
                        </Alert>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          <Col xl={6}>
            {!viewOnly && !animCre && (
              <Card>
                <CardBody>
                  <Container fluid>
                    {iniTextArray.map((itm) => {
                      return (
                        <Row>
                          <Col>
                            <h6>{itm.text}</h6>
                          </Col>
                          <Col>
                            <FormGroup row>
                              {/* <Label className="col-md-7 col-form-label">
                                Select
                              </Label> */}
                              <h6>Font Size</h6>
                              {/* <Col md={10}>
                                  <select
                                    onChange={(e) =>
                                      handleFontSize(itm, e.target.value)
                                    }
                                    className="form-control"
                                  >
                                    <option>2</option>
                                    <option>5</option>
                                    <option>10</option>
                                    <option>20</option>
                                  </select>
                                </Col> */}
                              <Col md={10}>
                                <input
                                  onChange={(e) =>
                                    handleFontSize(itm, e.target.value)
                                  }
                                  id="demoInput"
                                  type="number"
                                  defaultValue="2"
                                  min="2"
                                  max={isMobile ? "5" : "10"}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup row>
                              {/* <Label className="col-md-7 col-form-label">
                                Select
                              </Label> */}
                              <h6>Font Style</h6>
                              <Col md={10}>
                                <select
                                  onChange={(e) =>
                                    handleFontStyle(itm, e.target.value)
                                  }
                                  className="form-control"
                                >
                                  <option>Verdana </option>
                                  <option>Helvetica</option>
                                  <option>Tahoma</option>
                                  <option>Times New Roman</option>
                                  <option>Georgia </option>
                                </select>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup row>
                              {/* <Label className="col-md-7 col-form-label">
                                Select
                              </Label> */}
                              <h6>Font Color</h6>
                              <Col md={10}>
                                <Input
                                  style={{ marginLeft: "20px" }}
                                  className="form-control"
                                  type="color"
                                  defaultValue="black"
                                  onChange={(e) =>
                                    handleFontColor(itm, e.target.value)
                                  }
                                  id="example-color-input"
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                      );
                    })}
                  </Container>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

const SpeedContainer = styled.div`
  margin-left: 1em;
`;

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;
