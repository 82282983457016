import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function Wave1(props) {
  let anim = () => {
    // let y = props.word;
    // let wordArr = y.split("");

    // let checkSpan = document.querySelectorAll("span");
    // if (checkSpan.length === 0) {
    //   for (var i = 0; i < wordArr.length; i++) {
    //     let span = document.createElement("span");
    //     span.id = `wao${i}`;
    //     span.innerHTML = wordArr[i];
    //     document.getElementById("wordBoxWao").appendChild(span);
    //   }
    // }

    // let allSpan = document.querySelectorAll("span");

    // allSpan.forEach((i) => {
    //   i.style.fontSize = "66px";
    //   i.style.color = "white";
    //   i.classList.add("glow");
    //   i.style.margin = "3px";
    // });

    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxWao span");

    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `waodiv${count}`;

      document.getElementById("wordBoxWao").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#waodiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });

          count++;
          var div = document.createElement("div");
          div.id = `waodiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxWao").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `wao${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.classList.add("glow");
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`wao${i}`, props.wordArray[count].textColor);
          document.getElementById(`waodiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxWao span");

    var varTime = props.speed;

    let glowEven = () => {
      //even
      setTimeout(() => {
        for (let h = 0; h < wordArr.length; h += 2) {
          let ele = document.getElementById(`wao${h}`);
          if (ele !== null) {
            ele.style.color = map1.get(`wao${h}`);
            ele.style.textShadow = `0px 0px 50px ${map1.get(
              `wao${h}`
            )}, 0px 0px 50px ${map1.get(`wao${h}`)}`;
          }
        }
      }, (varTime / 2) * 100);
      return new Promise((resolve, reject) => {
        resolve("l");
      });
    };

    let dimEven = () => {
      //even
      setTimeout(() => {
        for (let h = 0; h < wordArr.length; h += 2) {
          let ele = document.getElementById(`wao${h}`);
          if (ele !== null) {
            ele.style.color = "white";
            ele.style.textShadow = "none";
          }
        }
      }, varTime * 100);
      return new Promise((resolve, reject) => {
        resolve("l");
      });
    };

    let glowOdd = () => {
      //odd
      setTimeout(() => {
        for (let h = 1; h < wordArr.length; h += 2) {
          let ele = document.getElementById(`wao${h}`);
          if (ele !== null) {
            ele.style.color = map1.get(`wao${h}`);
            ele.style.textShadow = `0px 0px 50px ${map1.get(
              `wao${h}`
            )}, 0px 0px 50px ${map1.get(`wao${h}`)}`;
          }
        }
      }, varTime * 100);
      return new Promise((resolve, reject) => {
        resolve("l");
      });
    };

    let dimOdd = () => {
      //odd
      setTimeout(() => {
        for (let h = 1; h < wordArr.length; h += 2) {
          let ele = document.getElementById(`wao${h}`);
          if (ele !== null) {
            ele.style.color = "white";
            ele.style.textShadow = "none";
          }
        }
      }, varTime * 1.5 * 100);

      return new Promise((resolve, reject) => {
        resolve("l");
      });
    };

    Promise.all([glowEven(), dimEven(), glowOdd(), dimOdd()]).then((res) => {
      console.log("finished");
    });
    let t = setInterval(() => {
      Promise.all([glowEven(), dimEven(), glowOdd(), dimOdd()]).then((res) => {
        console.log("finished");
      });
    }, varTime * 100);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxWao"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default Wave1;
