import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";
import { Modal } from "antd";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import Dashboard from "../../pages/Dashboard";

// const [backgroundColor, setBackgroundColor] = useState('#464347');
// const [fontColor, setFontColor] = useState('#8d32a8');
// const [fonttype , setFonttype] = useState('');
// const [selectedFile ,setSelectedFile ] =useState(null);
// const [text , setText] = useState('');

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      text: "",
      fonttype: "",
      fontColor: "",
      backgroundColor: "black",
      fontFamily: "Verdana",
      textCreated: false,
      selectedFile: null,
      animationIndex: -1,
      isModalVisibletnc: false,
      modalpp: false,
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }
  // const [text,,selectedFile,fonttype,fontColor,backgroundColor,setFonttype, setBackgroundColor,setFontColor ] = {...this.props};

  showModaltnc = () => {
    this.setState({
      isModalVisibletnc: true,
    });
  };

  handleOktnc = () => {
    this.setState({
      isModalVisibletnc: false,
    });
  };

  handleCanceltnc = () => {
    this.setState({
      isModalVisibletnc: false,
    });
  };

  showModalpp = () => {
    this.setState({
      modalpp: true,
    });
  };

  handleOkpp = () => {
    this.setState({
      modalpp: false,
    });
  };

  handleCancelpp = () => {
    this.setState({
      modalpp: false,
    });
  };

  setAnimationIndex = (e) => {
    this.setState({
      animationIndex: e,
    });
  };

  setSelectedFile = (file) => {
    this.setState({
      selectedFile: file,
    });
  };
  setFonttype = (f) => {
    this.setState({
      fonttype: f,
    });
  };
  setBackgroundColor = (b) => {
    this.setState({
      backgroundColor: b,
      selectedFileUrl: null,
    });
  };

  setBackgroundImage = (event) => {
    this.setState({
      backgroundColor: "black",
      selectedFile: event.target.files[0],
      selectedFileUrl: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleFontFamily = (b) => {
    this.setState({
      fontFamily: b,
    });
  };

  setFontColor = (c) => {
    this.setState({
      fontColor: c,
    });
  };

  setText = (text) => {
    this.setState({
      text: text,
    });
  };

  handleText = (text) => {
    if (text != "") {
      this.setState({
        textCreated: true,
      });
    }
  };

  notHandleText = (text) => {
    if (text != "") {
      this.setState({
        textCreated: false,
      });
    }
  };

  setText = (text) => {
    this.setState({
      text: text,
    });
  };

  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.isPreloader === true) {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";

        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      } else {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }
    }
  }

  componentDidMount() {
    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title = currentage + " | Signage Animator";
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }

    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }
  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header
            animIndex={this.state.animationIndex}
            toggleMenuCallback={this.toggleMenuCallback}
            toggleRightSidebar={this.toggleRightSidebar}
          />
          {!this.state.isMobile && (
            <Sidebar
              theme={this.props.leftSideBarTheme}
              type={this.props.leftSideBarType}
              isMobile={this.state.isMobile}
              setBackgroundColor={this.setBackgroundColor}
              handleFontFamily={this.handleFontFamily}
              setFontColor={this.setFontColor}
              setFonttype={this.setFonttype}
              backgroundColor={this.state.backgroundColor}
              fontColor={this.state.fontColor}
              fonttype={this.state.fonttype}
              selectedFile={this.state.selectedFile}
              setSelectedFile={this.setSelectedFile}
              text={this.state.text}
              setBackgroundImage={this.setBackgroundImage}
            />
          )}
          <div className="main-content">
            {/* {this.props.children} */}
            <Dashboard
              setAnimationIndex={this.setAnimationIndex}
              backgroundColor={this.state.backgroundColor}
              fontFamily={this.state.fontFamily}
              fontColor={this.state.fontColor}
              fonttype={this.state.fonttype}
              selectedFileUrl={this.state.selectedFileUrl}
              textVal={this.state.text}
              setText={this.setText}
              handleText={this.handleText}
              notHandleText={this.notHandleText}
              textCreated={this.state.textCreated}
            />
            {/* <Footer /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "1%",
                background: "white",
                position: "absolute",
                bottom: 0,
                width: "91vw",
              }}
            >
              <div>
                <a target="_blank" href="https://www.signageanimator.com/#who">
                  About US
                </a>
              </div>
              {/* onClick={() => showModaltc()} */}
              <div>
                <a href="#" onClick={() => this.showModaltnc()}>
                  Terms and conditions
                </a>
              </div>
              {/* onClick={() => showModalpp()} */}
              <div>
                <a onClick={() => this.showModalpp()} href="#">
                  Privacy Policies
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://www.signageanimator.com/#contact"
                >
                  Contact US
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Terms and conditions"
          visible={this.state.isModalVisibletnc}
          onOk={this.handleOktnc}
          onCancel={this.handleCanceltnc}
        >
          <p>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY USING THIS
            WEBSITE AND/OR PLATFORM YOU AGREE TO BE BOUND BY ALL OF THE BELOW
            TERMS AND CONDITIONS AND PRIVACY POLICY Signage Animator (“Company“,
            “We” or “Us” and their connotations) operates a website (URL:
            https://www.add-ed.com/ ) (together called as “Platform”) which is
            engaged in the service of online tutoring of computer programming
            and other subjects including mathematics to children. These terms
            and conditions (“Terms”) describe the terms on which the Company
            grants end users access to the Platform (hereinafter referred to as
            “Services”) and shall be read with the privacy policy available on
            https://www.add-ed.com/privacy policy . Anyone below 18 years of age
            is assumed to be a child (“Child”) and requires parental consent to
            use the platform. The parents of the Child or users above 18 years
            of age are hereinafter referred to as “You”. The term “Users” for
            the purposes of these Terms shall be read as You and/ or Child. The
            Company reserves the right, at its discretion, to change, modify,
            add, or remove portions of these Terms at any time by posting the
            amended Terms. Please check these Terms periodically for changes.
            Your continued use of the Platform or Services after the posting of
            changes constitutes your binding acceptance of such changes. In
            addition, when using any particular services or availing any
            promotional offer, you may be subject to additional terms and
            conditions, posted guidelines or rules, as may be applicable to such
            services and offers. All such guidelines, rules, product
            requirements or sometimes additional terms are hereby incorporated
            by reference into the Terms. 1. Terms of Service By choosing to
            visit the Platform and/or avail any Services provided by Company,
            you agree to be bound by these Terms. Please read the following
            information carefully. If you are a parent or guardian and you
            provide your consent for your child's use of the Platform, you agree
            to be bound by these Terms in respect to their use of the Platform.
            By your continued access or use of the Platform, you signify your
            agreement to be legally bound by the Terms set forth herein. If you
            do not agree to the Terms of this agreement, promptly exit this page
            and stop accessing the Services. 2. Description of Services The
            Company facilitates online computer science education services to
            children. We aim at providing lessons on programming, coding
            languages, and mathematics etc. to the Child. The concept is to
            create a virtual classroom, which helps the Child to learn without
            any restrictions on time and place. 3. User ID and Password In order
            to access the Company’s Platform and its services, You may have to
            create an account and disclose information including, but not
            limited to, (i) name, e-mail ID, photograph, location and other
            contact information (ii) gender and other demographics (iii) birth
            date and year to validate the current age of the Child (iv) your
            email address to acquire the parental consent. You must be at least
            18 years old to register on the Platform. If you are under 18 years
            old, you are not permitted to register on this site unless such
            registration is completed by a parent or legal guardian. You
            acknowledge that your user ID and password (“Participant Account“)
            is for your exclusive use only. Use or sharing of your Participant
            Account with another user or person is not permitted and is cause
            for immediate blocking of your access to the Platform, the Services
            and the content provided by the Company and shall lead to
            termination of this Agreement without any notice. You are solely
            responsible for maintaining the confidentiality of your Participant
            Account and for all activities that occur under it. You agree to
            immediately notify to the Company if you become aware of or have
            reason to believe that there is any unauthorized use of your
            Participant Account. You also agree to take all reasonable steps to
            stop such unauthorized use and to cooperate with the Company in any
            investigation of such unauthorized uses. The Company shall not under
            any circumstances be held liable for any claims related to the use
            or misuse of your Participant Account due to the activities of any
            third party outside of your control or due to tour failure to
            maintain the confidentiality and security of your Participant
            Account. 4. Trial Classes We offer One (1) free trial class to our
            new members so that you get an opportunity to experience the
            services provided by us. Only one (1) free trial class is permitted
            per new student. Multiple availment of trial classes by an
            individual either through his/her own account or through someone
            else's accounts, email IDs and/or in any other manner whatsoever,
            without the prior written permission of the Company is not allowed
            and shall amount to a breach of the present Terms. Any free trial
            class provided by the Company shall also be governed by these Terms.
            5. Modules and Curriculum The Company will have its sets of modules
            according to which the Child is expected to complete his/her levels.
            The Company has tutors who will be assisting the Child with their
            modules. The Company shall also grant you access to its material,
            content, curriculum, documents and other information and data
            (“Curriculum”) which may be in video, audio, written, graphic,
            recorded, photographic, or any other format in relation to the
            modules for which you have registered for. The Company reserves the
            right to amend, revise or update the Curriculum at any time. 6.
            License to Use The Company hereby grants You and the Child, the
            limited, non-transferable, non-exclusive, and revocable license to
            access, view and use the Platform only for the purposes of
            accessing, viewing, posting or submitting user material, using the
            embedded link function, placing store orders or for accessing
            information, applications and services. The Company reserves the
            right to suspend or deny, in its sole discretion, your access to all
            or any portion of the Platform. This license is limited to personal
            and non-commercial uses by You and your Child. Any rights not
            expressly granted to You herein are reserved to Company. You are not
            permitted to reproduce, transmit, distribute, sub-license,
            broadcast, disseminate, or prepare derivative works of the
            Curriculum, or any part thereof, in any manner or through any
            communication channels or means, for any purpose other than the
            limited purpose mentioned above, without the Company’s prior written
            consent. 7. Intellectual Property Rights You acknowledge that the
            Company is the sole and exclusive owner of the Platform, the
            services provided by the Company, the curriculum, and its content
            and as such the Company is vested with all the Intellectual Property
            Rights and other proprietary rights in the Platform, the Services,
            content and the curriculum. The Company may from time-to-time upload
            videos, audios/ sound recordings, content and other materials on the
            website which shall be the exclusive property of the Company. You
            undertake not reproduce, transmit, retransmit, distribute, publish,
            post, share or make available the said videos, audios/ sound
            recordings, content and other materials or any part thereof which
            are available on the Platform in any manner whatsoever.
            Additionally, the Company also retains all rights (including
            copyrights, trademarks, patents, designs, logos, trade-dress,
            trade-secrets, know-how as well as any other intellectual property
            right) in relation to all information provided on or via this
            Platform, including but not limited to all texts, graphics, photos,
            illustrations, apps and logos except the content, reading material,
            curriculum, tests and exercises made available to you from
            www.Code.org. You shall not copy, download, publish, distribute or
            reproduce any of the information contained on this Platform or
            social media in any form without the prior written consent of the
            Company. The Company retains all the rights in the video recordings,
            sound/audio recordings, images, photos, pictures/ images clicked
            during the classes, lectures delivered by the Company’s tutors, text
            and other material posted on the Platform and shall be the sole
            owner of the same. You undertake that you shall not record, make
            videos or sound/ audio recordings, take screen shots, click pictures
            and shall not download, publish, transmit, display, reproduce,
            transmit, distribute, post, share or make copies of any of the
            classes/ lectures that are conducted by the Company (including the
            trial classes), video recordings, sound/audio recordings, images,
            photos, pictures/ images, text or other material, whether in full or
            in part, unless you obtain prior written approval from the Company.
            Any recordings, videos, sound/audio recordings, screen shots,
            pictures, images, material or content which is obtained in any
            manner without the prior written consent of the Company shall amount
            to breach of the instant terms and conditions and you shall be
            solely liable for the said breach under the applicable laws. All
            other rights are reserved. 8. Use of the Platform by the Child You
            expressly acknowledge and undertake that: a. You are competent and
            have all the necessary legal rights to enter into this agreement on
            behalf of the child. b. You grant your consent to the Company for
            your child to attend and participate in the classes, courses, tests,
            sessions and/or any other program conducted and/or organized by the
            Company on its Platform and in relation to the services provided by
            the Company. You undertake that the participation of the Child and
            all the activities done by the Child will be under your direct and
            constant supervision. You further accept full and complete liability
            arising out of the child’s acts, whether direct or indirect.
            9.Linking You or the Child may establish a link to one or more
            site(s), provided that: A. the link is legal and not detrimental or
            damaging to and/or does not take unfair advantage of our reputation
            or business; B. such linking is not for advertising or promotional
            purposes (unless We have expressly agreed to it); C. the link is not
            from any website which promotes any political or religious views, or
            promotes or depicts intolerance, hatred, discrimination, violence,
            pornography or illegal activity; D. the link does not falsely or
            misleadingly imply or suggest that We endorse, approve of or are
            associated with the linked website, its web pages or any of its
            contents; and E. framing of any site on any other website is not
            allowed and You must not provide access to the site or part of it
            under any other URL. The Company may withdraw your or the Child’s
            right to link to any site without notice and at any time (acting in
            our sole discretion). Where any site and/or application contain
            links to other websites, web pages, resources, or mobile services
            which are proprietary to third-parties, other users, advertisers or
            sponsors, such websites, web pages, resources and mobile services
            are provided for your information only and You access them at your
            own risk. The Company will not be held liable or responsible for the
            content or operation of third-party websites, web pages, resources
            or mobile services. 10. Third Party Services You acknowledge that
            the Services provided by the Company uses and/or contains certain
            softwares, products and services which are developed and owned by
            third parties, the use of which is governed by terms and conditions
            of such third parties. Please read the User Agreement and Privacy
            Policy for these sites separately before using the said third party
            websites. By accessing the said third party websites, you agree to
            be bound by the respective user agreement and privacy policy of
            these third parties. Accordingly, you agree that the Company will
            not be responsible for such Third-Party software, products and
            services nor for any error, malfunction or defect in the Service
            resulted therefrom.” 11. Third Party Permission The Company has
            subscribed to various third-party service providers and you agree
            and acknowledge that, while accepting these terms, you explicitly
            grant permission to these service providers to use your information.
            In the event of any dispute between the third party and you the
            company shall not be held liable in any matter whatsoever. 12. User
            Content The Company offers You and the Child the opportunity to
            submit, post, display, transmit, perform, publish, distribute or
            broadcast content and materials, including, without limitation,
            articles, commentaries, photographs, text, music, video, audio
            recordings, computer graphics, pictures, data, questions, comments,
            suggestions or personally identifiable information. The Company may
            remove content at its discretion, subject only to its express
            obligations in respect of peer-reviewed articles. The Child may
            choose to write and post reviews on the Platform. We do not
            encourage your Child to post any Personal Information on the
            Platform and the ill effects of such revelation shall be borne by
            you. However, you can request the Company via e-mails to delete any
            such information posted by the Child. For the purpose of
            verification, the Company will review and scrutinize such emails
            before responding. You warrant and represent that your content, and
            the content of any website from which You include a link to any
            site, or to which You post a link from a site, will not be
            inappropriate. Without limitation, content (and the content of
            third-party websites) may be considered inappropriate if: a. it is
            misleading in any way, and/or it gives a false impression as to its
            origins or approvals; b. it is defamatory, plagiarized (including
            plagiarism from your own work), abusive, malicious, threatening,
            false, misleading, offensive, insulting, discriminatory, profane,
            harassing, racist, sexist, indecent, obscene, pornographic, hateful
            or it advocates violence; c. it is in breach of confidentiality or
            another person’s privacy or other rights, or of any duty owed by
            You; d. it prejudices any active or pending legal proceedings of
            which You are aware; e. it contains accusations of impropriety or
            personal criticism of our personnel, editors or reviewers; f. it
            infringes any intellectual property rights proprietary to the
            Company or any third party; g. it is technically harmful (including
            content containing, without limitation, computer viruses, logic
            bombs, trojan horses, worms, harmful components, corrupted data or
            other malicious software, harmful data or conduct and/or contains
            any other element which is intended to harm the Company or any third
            party, or to carry out or facilitate any fraudulent or dishonest
            transaction); h. it advertises or promotes any product or service or
            makes any requests for donations or financial support; i. it is spam
            or junk content; j. it impersonates another person or otherwise
            misrepresents your identity, affiliation or status; k. it would be
            considered a criminal or statutory offense in any jurisdiction, or
            gives rise to civil liability, or is otherwise unlawful; and/or l.
            it is in breach of these Terms and/or of any Additional Terms. The
            Company will not be held responsible or liable for any of the User
            content provided by You on the Company website. You must not attempt
            to avoid or undermine any protections that the Company may put in
            place for the security and operation of any website. You hereby
            explicitly consent and grant permission to the Company to record,
            make videos, audio/sound recordings, take screenshots during the
            classes/ lectures that are conducted by the Company on its Platform
            to the extent required to improve the services offered by the
            Company. All rights, ownership, and intellectual property in the
            ‘User Content’ created by your child on or by using the platform,
            during the period of the tutorial classes or the course, shall vest
            in the Company. You hereby explicitly consent and transfer all such
            rights, ownership, and intellectual property in the ‘User Content’
            to the Company for a worldwide, royalty-free, and perpetual use. The
            Company shall have the sole and exclusive right to use, copy,
            modify, adapt, prepare derivative works from, distribute, perform,
            and display any/all such ‘User Content’. You and your child
            undertake to use the Company’s platform and intellectual property
            for only the purposes envisaged in the instant terms and shall not
            use the same for any unauthorized or unlawful purpose. Should you
            and your child use the Company’s platform or intellectual property
            with any third party’s intellectual property in any manner, then you
            and your child will be solely responsible to obtain all necessary
            permissions, authorizations and licenses from the said third party.
            In the event you and your child use the Company’s platform and
            intellectual property beyond the limited rights granted to you and
            your child under these terms, then you and your child shall be
            solely responsible for the same and shall indemnify the company for
            all losses, claims and damages in this regard. You and your child
            will be solely responsible to ensure that any content or
            intellectual property created by the child shall not contain any
            bugs, virus and malware or infringe any third party’s intellectual
            property rights or violate any applicable laws (including data
            protection and privacy laws) in any manner. By uploading,
            submitting, creating, or publishing your User Content to or through
            the Services, you represent, and warrant that: • you are the creator
            and owner of your User Content and that you have the authority to
            use or have the necessary licenses, rights, consents, and
            permissions to publish the User Content you submit • your User
            Content does not and will not o infringe, violate, or misappropriate
            any third-party right, including any copyright, trademark, patent,
            trade secret, moral right, privacy right, right of publicity, or any
            other intellectual property or proprietary right o slander, defame,
            libel, or invade the right of privacy, publicity or other property
            rights of any other person • your User Content does not contain any
            viruses, adware, spyware, worms, or other malicious code. 13.
            Intended Purpose of Use Any application, code or content created
            using the Company’s platform are intended to be used solely as
            prototypes and for evaluation of validity and practicability of
            ideas. All such applications, codes and/or content may be solely
            created for educational purposes and gathering feedback by the
            students and are in no way fit for or meant to be used for any
            commercial use. Any other use of such apps, codes or content, other
            than the use specified herein is prohibited by the Company and the
            Company shall not be liable for the same and you and your child
            shall indemnify the company for any loss, claims or damages suffered
            by the Company in this regard. 14. Fitness of Use Any and/or all
            apps built using the Company platform by the users during the period
            of their permitted use of the platform are solely meant for
            educational and evaluation purposes as part of the users training
            program, and hence the same are expected to have limited
            functionality and use and are thus not fit to be used commercially
            or to be adopted for any use as opposed to the intended use
            prescribed in these terms. The apps might have unresolved technical
            bugs and security concerns including but not limited to viruses,
            data safety, account protection, insufficiency of security protocols
            and non-encryption. Any personal information, passwords and/ or any
            other details or sensitive data provided might become available to
            other users of the app. Any such commercial or non-authorized use of
            these apps shall be at the sole risk and discretion of the users and
            third parties and the Company in no way whatsoever promotes or
            authorizes the same. You and your child shall be solely responsible
            for any such unauthorized use, promotion and/or commercialization of
            the app/code/content created by your child or any other user of the
            Company platform and shall indemnify the Company for all losses,
            claims and damages in this regard. 15. User Communication You hereby
            explicitly consent to receive email, telephone, WhatsApp or text
            messages from Signage Animator, for the purpose of providing alerts
            and information related to services. Reply 'STOP' on the same number
            to stop receiving any further SMS. Reply 'HELP' to get help.
            Standard data charges are applicable for SMS. 16. Rule of Conduct
            Users must comply with the laws that apply to You in the location
            that You access Company’s Services from. If any laws applicable to
            You restrict or prohibit You from using Services of Company, You
            must comply with those legal restrictions or, if applicable, stop
            accessing and/or using the Services of Company. You promise that all
            the information You provide to Company on accessing and/or using the
            Services of Company is and shall remain true, accurate and complete
            at all times. Notwithstanding any other provision of these Terms You
            agree and undertake not to: a) Hack, attempt to hack, modify, adapt,
            merge, translate, decompile, disassemble, reverse engineer or create
            derivative works out of the Service or any part of them (save to the
            extent which expressly cannot be prohibited in accordance with the
            applicable mandatory law in your jurisdiction); b) Remove, disable,
            modify, add to or tamper with any program code or data, copyright,
            trademark or other proprietary notices and legends contained on or
            in the Service; c) Create software which mimics any data or
            functionality in the Service; d) Use or deal in the Service except
            as permitted by these Terms; e). Include contact details intended to
            enable communication outside of the Service, in any Communication;
            f) Use your access to the Service, or information gathered from it,
            for the sending of unsolicited bulk email; g) Make any public,
            business or commercial use of the Service or any part of them; h)
            Provide hypertext links, URL links, graphic links, hyperlinks or
            other direct connection for profit or gain to the Service without
            prior written permission of Company; i) Make the Service or any part
            of it available to any third party (please note this does not stop
            you from fairly and honestly providing links to the Platform, or
            showing either to other people); j) Use or process the Service or
            any part of them unfairly or for any illegal or immoral purpose; or
            k) Delete or obscure any copyright or other proprietary notice on
            the Service The Service may only be accessed and used via authorized
            servers. You must take no action to bypass authorized servers and/or
            use third party software to modify any aspect of the Service,
            whether for the purpose of securing an unfair advantage over other
            users, and You expressly consent to Company’s monitoring your
            computer's random access memory for the purpose of identifying said
            unauthorized third-party programs. 17. Limited Liability You are
            held personally liable for any violation of a third party's rights
            by You and your Child. You agree to reimburse Company for all
            damages resulting from the culpable non-observance of the
            obligations of these Terms. You release the Company from all
            eligible claims that other users or third parties may file against
            Company due to a violation of their rights by content posted by the
            user or due to a violation of other obligations. You shall assume
            the costs of Company legal defense, including all court and legal
            fees. This condition does not apply if the you are not responsible
            for the infringement. Company ensures that the information and the
            training facilitated by the tutors on the Platform is accurate but
            does not guarantee or warrant its accuracy, adequacy, correctness,
            validity, completeness, or suitability for any purpose, and accepts
            no responsibility with respect to the information and coaching given
            by the tutors on the Platform. You agree and understand that Company
            does not states or claims any warranty for the quality of lectures
            delivered by the tutors listed on our Platform. The Company
            undertakes the scrutiny of the curriculum delivered by the tutors
            however, the Company does always not guarantee the quality of
            lessons delivered to the Child. At any instance of live interactive
            classes, if the tutor uses any sexual or abusive language or depicts
            any forced sexual acts, or pornographic images or does any
            unethical, unlawful or immoral act, then in such cases, please
            immediately inform the Company at the contact provided below in
            Clause 18. Termination Without limiting any other rights that
            Company may have, Company may remove, restrict, cancel or suspend
            access to and/or use of the Platform, Services provided by the
            Company and any part of it, if Company considers (in the sole
            discretion of Company) that You have breached any of these Terms.
            You may also terminate your agreement with the Company by ceasing to
            access the Platform, Service, deleting all copies of the Service or
            part thereof within your control. Termination shall not affect any
            rights or remedies, which have accrued up to the time of
            termination. 19. Severability If any court or competent authority
            finds that any provision of these Terms (or part of any provision)
            is invalid, illegal or unenforceable, that provision or
            part-provision shall, to the extent required, be deemed to be
            deleted, and the validity and enforceability of the other provisions
            of these Terms shall not be affected. 20. Assignment Any rights and
            licenses granted hereunder shall not be transferred or assigned by
            you but, shall be assigned by the Company. Any attempted transfer or
            assignment in violation hereof shall be considered as null and void.
            21. Waiver No waiver of any terms of this agreement shall be treated
            as a further or continuing waiver of such term or any other term and
            the Company’s failure to assert any right or provision under this
            agreement shall not constitute a waiver of such right or provision.
            22. Government Laws and Jurisdiction Any dispute or difference
            either in interpretation or otherwise, of the Terms and other
            Policies on the Platform, between the parties hereto, shall be
            referred to an independent arbitrator who will be appointed by
            mutually and his decision shall be final and binding on the parties
            hereto. The above arbitration shall be in accordance with the
            Arbitration and Conciliation Act, 1996 as amended from time to time.
            The seat of arbitration shall be held in Mumbai and the language
            shall be in English. The Terms shall be construed in accordance with
            the applicable laws of India. For proceedings arising therein the
            Courts in Mumbai shall have exclusive jurisdiction. Without any
            prejudice to particulars listed in Clause 26 above, the Company
            shall have the right to seek and obtain any injunctive, provisional
            or interim relief from any court of competent jurisdiction to
            protect its trade-mark or other intellectual property rights or
            confidential information or to preserve the status quo pending
            arbitration. 23. Entire Agreement This Agreement, along with the
            Privacy Policy, Terms of Use, and any additional guidelines, rules,
            and/or disclaimers posted on the Platform constitutes the entire
            agreement governing your use of our Website and supersedes any prior
            agreements, if any, relating to any matter dealt within this
            Agreement. 26. The Company will make all efforts to take any and all
            necessary actions as per the applicable law. However, the Company
            explicitly disclaims any liability or responsibility in the event of
            such a circumstance. Company will not be held responsible for any
            unethical, illegal acts performed by the Child on the advice of
            tutor and it shall be the your responsibility to closely monitor the
            activities of your Child while accessing the Platform. In no event
            shall the Company be liable for any direct, indirect, incidental,
            special or consequential damages, or damages for loss of profits,
            revenue, data or data use, incurred by you or any third-party,
            whether in an action in contract or tort, arising from your access
            to, or use of, the Platform or any content provided on or through
            the Platform. The Company facilitates educational and informational
            on an "as is" basis and is liable only to provide its services with
            reasonable skill and care. The Company's liability for any and all
            claims in the aggregate, arising out of or related to your use of
            the Platform, shall not under any circumstances exceed the amounts
            actually paid by You to the Company for its Services. External Sites
            have not been verified or reviewed by Company and all use and access
            of External Sites is made at your own risk. “External Sites“ means
            third party websites and online services to which the Service links.
            The Company gives no other warranty in connection with the Service
            and to the maximum extent permitted by law, Company excludes
            liability for: a) any loss or damage of any kind howsoever arising,
            including any direct, indirect, special, punitive or consequential
            loss whether or not such arises out of any problem which Company
            have been made aware of; b) the accuracy, currency or validity of
            information and material contained within any communications or the
            Service; c) any interruptions to or delays in updating the Service;
            d) any incorrect or inaccurate information on the Service; e) the
            infringement by any person of any copyright or other intellectual
            property rights of any third party through any communication or use
            of the Service; f) the availability, quality, content or nature of
            External Sites; g) any transaction involving External Sites; h). any
            amount or kind of loss or damage due to viruses or other malicious
            software that may infect a user's computer equipment, software, data
            or other property caused by persons accessing, using or downloading
            the Service, or any Communication (save that, where digital content
            supplied to You by Company or on behalf of Company through the
            Service causes damage to your digital content or devices You may be
            entitled to compensation or repair or replacement, in which case
            kindly inform to Company); i) all representations, warranties,
            conditions and other terms and conditions which but for this notice
            would have an effect The Company does not warrant that the operation
            of the Service will be uninterrupted or error-free. The Company will
            not be liable in any amount for failure to perform any obligation
            under this agreement if such failure is caused by the occurrence of
            any unforeseen contingency beyond the reasonable control of Company
            including Internet outages, communications outages, fire, flood, war
            or act of God. Except as provided above there are no other
            warranties, conditions or other terms and conditions, express or
            implied, statutory or otherwise, and all such terms and conditions
            are hereby excluded to the maximum extent permitted by law. You
            agree that in relation to your use of the Service You will not in
            any way conduct yourself in a manner which is unlawful, or which
            gives rise to civil or criminal liability or which might call
            Company or the Service into disrepute. You agree that You are and
            shall remain responsible for maintaining the confidentiality of your
            password and username and for all activities that occur under your
            account. No amendment will take place if such amendment would
            substantially disrupt the contractual balance between the parties.
            Users will be informed of any amendments to the general Terms via
            the Platform or via notice by email or in writing.{" "}
          </p>
        </Modal>
        <Modal
          title="Privacy policies"
          visible={this.state.modalpp}
          onOk={this.handleOkpp}
          onCancel={this.handleCancelpp}
        >
          <p>
            This Privacy Policy outlines Signage Animator’s approach to privacy
            to fulfil its obligations under the applicable privacy laws.
            Throughout this document, the terms “we”, “us”, “our” & “ours” refer
            to Signage Animator. And the terms “you”, “your” “yours” & “you
            and/or your child “refer to YOU (as the User). This Privacy Policy
            applies to all your PERSONAL DATA processed by us, whether in
            physical or electronic mode. This Privacy Policy is meant to help
            you understand what information we collect, why we collect it, and
            how you can update, manage, export, and delete your information and
            shall be read with the Terms and Conditions found on (URL:
            https://www.signageanimator.com/) Please note that this Privacy
            Policy is applicable to all instances where we play the role of a
            DATA CONTROLLER of your Personal Data, when we collect and process
            personal data about you for offering our products or services. We
            are committed to keeping your and your child's Personal Data private
            and secure. We process any Personal Data we collect from you and
            your child in accordance with the applicable laws and regulations
            and the provisions of this Privacy Policy. Please read the following
            carefully to understand our practices regarding your and your
            child's Personal Data and how we treat it. By choosing to visit
            and/or avail any Services provided by us, you agree to this Privacy
            Policy (as may be amended from time to time). Please read the
            following information carefully. You must be at least 18 years old
            to register on our site. If you are under 18 years old, you are not
            permitted to register unless such registration is completed by a
            parent or legal guardian. By your continued access or use of the
            Platform, you signify your agreement to be legally bound by Privacy
            Policy set forth herein. If you do not agree to the Privacy Policy
            of this agreement, promptly exit this page and stop accessing the
            Services. Personal Data ‘Personal Data’ means any information that
            may be used to identify an individual, directly or indirectly, in
            particular by reference to an identifier, including not limited to,
            a first and last name, physical address, email address, online
            identifier or other contact information and/or identity proof
            documents. Children’s Privacy We need to collect your and your
            child's Personal Data to provide our services to you and your child.
            We are committed to protecting the privacy of children who use our
            sites and applications. Whenever we refer to a “child” in this
            privacy policy, we mean a child under the age of 13 for US users,
            and under the age of 16 for EU users. If you are a parent or
            guardian and you believe that your child has provided us with
            Personal Data without your consent, please contact us as at
            queries@add-ed.com. We will dispose of that data in accordance with
            applicable laws and regulations. Parents can always refuse to permit
            us to collect further Personal Data, request to review their child's
            Personal Data, or request that we delete from our records the
            personal data collected from or about their child. Please keep in
            mind that a request to delete records may lead to a termination of
            an account, membership, or other service, as the nature of our
            services requires collection of Personal Data. To exercise any of
            these options, please contact us at queries@add-ed.com. Please be
            aware that to protect children’s privacy and security, we will take
            reasonable steps to verify a parent or legal guardian’s identity
            before granting access to any personal data. What Personal Data do
            we Collect & Process? Categories of Personal Data that we collect
            and process are as follows: • Demographic & Identity Data of you/ or
            your child (name, email address, contact number, photograph,
            location), gender, school name, birth date and year of the child to
            validate age) • Financial Data (for e.g. account, payment details,
            invoice details) • Online Identifiers and other Technical Data (for
            e.g. IP address, transaction logs, device details) • Online training
            sessions are recorded and used for quality audits and teacher
            training • Projects, quizzes, activities, apps developed,
            performance completed as part of our programs. • Your child may also
            choose to provide Personal Data about themselves in the content they
            post on our website. However, as outlined above, we require and have
            safeguards in place to ensure that you first consent to our
            collection and use of this Personal Data. Where do we obtain your
            Personal Data from? Most of the Personal Data we process is provided
            by you directly to us when you register to use our products and/or
            services including the registration for a free trial. This also
            includes the Personal Data collected automatically and in the
            background by us when you use our website and application(s).
            Certain information might be collected automatically as you or the
            child navigate through the Platform (which may include usage
            details, IP address, device ID and type, your browser type and
            language, the operating system used by the device, access times, and
            information collected through cookies, web beacons and other
            tracking technologies). Note that we do not collect Personal Data
            from children for the purposes of behavioural advertising. We do not
            allow third-party behavioural trackers in areas intended to be used
            by children. In furtherance of your usage of our Platform, we
            require the parents to create a G-mail account on behalf of the
            Child in accordance with Google’s terms and conditions. This account
            is created in order to enable the Child to sign into and access
            other various coding programs and/or educational course curriculum
            available across the internet as listed on the Platform. We shall
            collect information from this G-mail account that you use to connect
            with or use our Services and access other coding programs and/or
            educational course curriculum made available through our Platform.
            When you sign in with this G-mail account, you consent to our
            collection, storage, and use, in accordance with this Privacy
            Policy, of the information that you make available to us through the
            internet. We may also receive Personal Data about you from third
            parties and publicly available sources of information. How do we use
            your Personal Data? • To verify your identity • To deliver our
            products and services • To improve our products and services • To
            perform quality audits and teacher training • To communicate with
            you regarding existing products and services availed by you,
            including notifications of any alerts or updates or surveys • To
            evaluate, develop and improve our products and services • For market
            and product analysis and market research • To send you information
            about our other products or services which may be of interest to you
            (you may always opt out of this ongoing marketing communications by
            clicking an “Unsubscribe” link in the footer of each email) • To
            handle enquiries and complaints • To enable you to avail of our
            Services, and ensure efficient customer care experience and develop
            new features • To comply with legal or regulatory requirements • To
            investigate, prevent, or take action regarding illegal activities,
            suspected fraud and situations involving potential threats to the
            safety of any person • To furnish your information to service
            partners and providers only to the extent necessary for delivering
            the relevant services (e.g. scheduling service and maintenance,
            providing emergency assistance); • With the necessary consent, to
            publicize, post and display the projects and/or apps created by your
            child on our website or related pages or any other social media
            platforms along with your personal information in accordance with
            applicable laws Lawful Bases of processing your Personal Data We
            process your Personal Data by relying on one or more of the
            following lawful bases: • You have explicitly agreed to/consented to
            us processing your Personal Data for a specific reason. This
            includes, without limitation, any information that you have made
            public through your social media account, information that the
            social media service shares with us or information that is disclosed
            during the sign-up and sign-in processes. o The processing is
            necessary for the performance of the contract we have with you or to
            take steps to enter into a contract with you o The processing is
            necessary for compliance with a legal obligation we have Where the
            processing is based on your consent, you have the right to withdraw
            your consent at any point in time. Please note that should the
            withdrawal of consent result in us not being able to continue
            offering our products and services to you, we reserve the right to
            withdraw or cease our products and services to you upon your consent
            withdrawal. You may withdraw consent by contacting us with a written
            request to the contact details specified below in the ‘Contact Us’
            section. Upon receipt of your request to withdraw your consent, the
            consequences of withdrawal may be communicated to you. Upon your
            agreement to the same, your request for withdrawal will be
            processed. When do we share your Personal Data with third parties?
            We may use third parties in the provision of our products and
            services to you. We may share your Personal Data with such third
            parties. We have appropriate contracts in place with all such third
            parties. This means that they are not permitted to do anything with
            your Personal Data which is outside of the scope specified by us.
            They are committed to hold your Personal Data securely and retain it
            only for the period specified in our contracts with them 1. Reasons
            for sharing your Personal Data with third parties: We may disclose
            your Personal Data to third parties only where it is lawful to do
            so. This includes instances where we or they: o need to provide you
            with products or services o have asked you for your consent to share
            it, and you have agreed o have a legal obligation to do so. For
            e.g., to assist with detecting and preventing fraud o have a
            requirement in connection with regulatory reporting, litigation or
            asserting or defending legal rights and interests 2. We may also
            disclose your Personal Data to appropriate authorities if we believe
            that it is reasonably necessary to comply with a law, regulation,
            legal process; protect the safety of any person; address fraud,
            security, or technical issues; or protect our rights or the rights
            of those who use our products & services. 3. With whom your Personal
            Data may be shared: We may disclose your Personal Data to the
            following third parties: o who work for us or provide services or
            products to us o law enforcement authorities, government
            authorities, courts, dispute resolution bodies, regulators, auditors
            and any party appointed or requested by applicable regulators to
            carry out investigations or audits of our activities o statutory and
            regulatory bodies, authorities (including the government)
            investigating agencies and entities or persons, to whom or before
            whom it is mandatory to disclose Personal Data as per the applicable
            law, courts, judicial and quasi-judicial authorities and tribunals,
            arbitrators and arbitration tribunals. o Online training sessions
            are recorded and used for quality audits and teacher training. We
            maintain complete confidentiality of the recordings and under no
            circumstances they will be accessible to or shared with any third
            party. Use of Cookies and other Tracking Mechanisms We may use
            cookies and other tracking mechanisms on our website and other
            digital properties to collect data about you. Cookies are small text
            files that are placed on your computer by websites that you visit.
            They are widely used in order to make websites work, or work more
            efficiently, as well as to provide information about your actions to
            the owners of the website. Most web browsers allow you some control
            of cookies through browser settings. We offer certain features that
            are only available using a cookie. Outlined below are the categories
            of cookies along with a description of what they are used for. •
            Strictly Necessary Cookies - These cookies are needed to run our
            website, to keep it secure and to comply with regulations that apply
            to us. • Functional Cookies – We may use functional cookies on our
            website. These cookies allow us to remember information you enter or
            choices you make (such as your username, language, or your region)
            and provide you with enhanced, more personalised features. •
            Performance/Analytics Cookies – We may use performance/analytics
            cookies on our website. These cookies collect information about how
            visitors use our website and services, including which pages
            visitors go to most often and if they receive error messages from
            certain pages. It is used to improve how our website functions and
            performs. • Marketing Cookies – We may use marketing cookies on our
            website. These cookies help us decide which of our products,
            services and offers may be relevant for you. We may use this data to
            tailor the marketing and ads you see on our own and other websites
            and mobile apps, including social media. For instance, you may see
            our ads on other sites after you have been to our website. We do not
            use behavioural advertising cookies in areas that are intended to be
            used by child users. How do we secure your and your child's Personal
            Data? We are committed to protecting your and your child's Personal
            Data in our custody. We take reasonable steps to ensure appropriate
            physical, technical and managerial safeguards are in place to
            protect Personal Data from unauthorized access, alteration,
            transmission and deletion. We ensure that the third parties who
            provide services to us under appropriate contracts take appropriate
            security measures to protect Personal Data in line with our
            policies. How long do we keep your and your child's Personal Data?
            We keep the Personal Data we collect about you and your child for as
            long as it is required for the purposes set out in this Privacy
            Notice and for legal or regulatory reasons. We may take reasonable
            steps to delete or permanently de-identify your Personal Data that
            is no longer needed. No Sale of Personal Data We shall not sell any
            Personal Data of yours and/ or the children for the purpose of
            marketing, advertising, or any other third-party use, except in
            accordance with express consent obtained from you. Data Security We
            always strive to keep your and your child's Personal Data safe. The
            security of your and your child's Personal Data is important to us,
            but remember that no method of transmission over the Internet, or
            method of electronic storage, is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Data, we
            cannot guarantee its absolute security. • Safety in the operating
            environment: we store your and your child's Personal Data in a safe
            operating environment and it can only be accessed by employees,
            representatives and service providers on a need-to-use basis. We
            comply with industry standards and the law to protect your and your
            child's Personal Data • The payment information: we adhere to
            confidentiality and security according to industry standards. Links
            to other Websites Our website may contain links to websites of other
            organizations. This privacy notice does not cover how those
            organizations process your Personal Data. We encourage you to read
            the privacy notices on the other websites you visit. During and as
            part of the classes or curriculum the child might be required to
            access, visit, browse and make use of third-party websites and
            content, the use of which is governed by terms and conditions of
            such third parties. The information the third-party site or platform
            collects is subject to the third-party site or platform’s privacy
            practices. Privacy choices you have made on the third-party site or
            platform will not apply to our use of the information we have
            collected directly through our applications. We encourage you to be
            aware when you leave our sites or applications and to read the
            privacy policies of other sites that may collect your personal data.
            Your Rights You have the following rights regarding Personal Data
            that we process about you: • Access: You have a right to know what
            personal data we hold about you and to obtain a copy. • Data
            portability: Subject to law, you have a right to obtain in machine
            readable format the personal data you have provided to us. •
            Rectification and erasure: You have a right to have incomplete,
            incorrect, unnecessary or outdated personal data about you deleted
            or updated. • Withdraw your consent: You have a right to withdraw
            your consent to process your and your child’s personal data. • The
            right to object. You have the right to object to our processing of
            your Personal Data. • The right of restriction. You have the right
            to request that we restrict the processing of your Personal Data.
            You may exercise your rights by managing your account and choices
            through our products and services or, if that is not possible, by
            contacting us. In some cases, if you withdraw your consent or wish
            us to delete or stop processing your personal data, we may not be
            able to continue to provide the services to you. If you are not
            satisfied with what we provide when you exercise your rights, you
            can let us know by contacting us. Contact Us For any further queries
            and complaints related to privacy, or exercising your rights, you
            could reach us at: Contact Email Address: info@ulivesmart.com
            Organization's Name: Bitbang Enterprises Address: : 470/1 Plot No.
            280, Shivaji Market, Pitampura, Delhi – 110034 Phone number:
            9811880288 Notification of changes We regularly review and update
            our Privacy Notice to ensure it is up-to-date and accurate. Any
            changes we may make to this Privacy Notice will be in effect
            immediately after being posted on this page. We reserve the right to
            update or change our Privacy Policy at any time and you should check
            this Privacy Policy periodically. Your continued use of the Service
            after we post any modifications to the Privacy Policy on this page
            will constitute your acknowledgment of the modifications and your
            consent to abide and be bound by the modified Privacy Policy.
          </p>
        </Modal>

        <Rightbar />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));
