import React from "react";
import { message, notification, Modal } from "antd";
import API from "../../config/config";
import Authbg from "../../assets/images/authgif.gif";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

import signangel from "../../assets/images/signangel.png";

// Redux
import { Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions

// import images
import logodark from "../../assets/images/logo-dark.png";

export default function ForgotDel() {
  let history = useHistory();
  const [username, setUsername] = React.useState("");
  const [userpassword, setUserpassword] = React.useState("");
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [state, setState] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (username === "") {
      notification.error({ message: "Provide username" });
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          otp: otp,
          username: username.toLowerCase(),
          password: userpassword,
        }),
      };
      fetch(API.api_route + `/v1/user/password/`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "success") {
            setIsModalVisible(false);
            history.push("login");
            message.success("Password changed successfully !");
          } else {
            notification.error({ message: data.status });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleSubmit() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      API.api_route + `v1/user/password/?username=${username}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "success") {
          message.success("Verify your account !");
          showModal();
        } else {
          notification.error({ message: data.status });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  var handleUsername = (e) => {
    setUsername(e.target.value);
  };

  var handlePassword = (e) => {
    setUserpassword(e.target.value);
  };

  return (
    <div>
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={signangel} height="34" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Reset Password
                            </h4>
                            <p className="text-muted">
                              Please reset your password.
                            </p>
                          </div>

                          {/* {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null} */}

                          <div className="p-2 mt-2">
                            <AvForm className="form-horizontal">
                              <FormGroup className="auth-form-group-custom mb-2">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Email</Label>
                                <AvField
                                  name="username"
                                  //   value={this.state.username}
                                  type="text"
                                  onChange={handleUsername}
                                  className="form-control"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter Email"
                                />
                              </FormGroup>

                              <div className="mt-1 text-center">
                                <Button
                                  onClick={handleSubmit}
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-1 text-center">
                            <Modal
                              title="Verify"
                              visible={isModalVisible}
                              onOk={handleOk}
                              onCancel={handleCancel}
                            >
                              <h6>{username}</h6>
                              <p>OTP has been sent to the Registered Email</p>
                              <AvForm>
                                <FormGroup className="auth-form-group-custom mb-2">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="userpassword">Username</Label>
                                  <AvField
                                    name="userEmail"
                                    defaultValue={username}
                                    type="text"
                                    onChange={handleUsername}
                                    className="form-control"
                                    id="username"
                                    placeholder="Enter Username"
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-2">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="userpassword">Otp</Label>
                                  <AvField
                                    name="otp"
                                    //   value={this.state.password}
                                    type="text"
                                    onChange={(e) => {
                                      setOtp(e.target.value);
                                    }}
                                    className="form-control"
                                    id="otp"
                                    placeholder="Enter OTP"
                                  />
                                </FormGroup>

                                <FormGroup className="auth-form-group-custom mb-2">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="userpassword">
                                    New Password
                                  </Label>
                                  <AvField
                                    name="password"
                                    //   value={this.state.password}
                                    type="password"
                                    onChange={handlePassword}
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                  />
                                </FormGroup>
                              </AvForm>
                            </Modal>
                            {/* <p>
                              Change Password ?{" "}
                              <Link to="#" onClick={showModal}>
                                click
                              </Link>{" "}
                            </p> */}

                            <a
                              href="https://evrenglobalsolutions.com/"
                              target="_blank"
                            >
                              {" "}
                              <p>
                                © 2021 EGS. Crafted with{" "}
                                <i className="mdi mdi-heart text-danger"></i> by
                                EGS
                              </p>
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col style={{ background: "black" }} lg={8}>
                {/* <div className="authentication-bg">
                  <div className="bg-overlay">
                    
                  </div>
                </div> */}
                <img
                  src={Authbg}
                  style={{ width: "100%" }}
                  className="authimg img-fluid"
                  alt="authbg"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}
