import React, { useState, useEffect } from "react";
// import style from './Animation1.module.css';
import styled, { keyframes } from "styled-components";
import {
  Animate,
  Animate3,
  Animate4,
  Animate5,
} from "../styledanimation/StyledAnimation";
const Heading = styled.h1`
  font-size: 2vh;
  font-weight: 400;
`;

// one word at a time
// const animate = keyframes`
// 0%,80%{
//     color: ${props=>props.color};
//     text-shadow: none;
// }

// 100%{
//     color: #fff;
//     text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
// }
// `;

// spiker
// const animate = keyframes`
// 0%{
//     color: ${props=>props.color};
//     text-shadow: none;
// }

// 100%{
//     color: #fff;
//     text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
// }
// `;

// one word difusion
let animateFunc = (color) => {
  const animate = keyframes`
  0%,100%{
      // color: ${(props) => props.color};
      opacity:0.2;
      // text-shadow: none;
      color: ${color};
  
      text-shadow: 
      0 0 20px #fff,
      0 0 30px ${color}, 
      0 0 40px ${color};
  
  }
  50%{
      opacity:1;
      color: ${color};
      text-shadow: 
      0 0 20px #fff,
      0 0 30px ${color}, 
      0 0 40px ${color}, 
      0 0 80px ${color};
  }
  `;

  return animate;
};

export default function Animation19(props) {
  const [letterArray, setLetterArray] = useState([]);

  // const text = props.word.split("");
  let words = "";
  props.wordArray.map((itm) => {
    words += itm.text + "%";
  });
  const text = words.split("");

  let len = text.length;
  let t = 0.25;
  let i = 1;
  let ink = 0;
  const letters = text.map((letter) => {
    if (letter === "%") {
      ink++;
      return <br />;
    } else {
      if (t >= 1) {
        t = 0.25;
        i += 1;
        i = i % 4;
        if (i === 1) {
          t = 1;
        }
        if (i === 2) {
          t = 0.33;
        }
        if (i === 3) {
          t = 0;
        }
        if (i === 4) {
          t = 0.33;
        }
      }
      t = t + 0.25;

      i++;
      // console.log(letter);
      return (
        <div
          style={{
            display: "inline",
            fontSize: `${props.wordArray[ink].textSize}`,
          }}
        >
          <Animate
            delay={t + "s"}
            animate={animateFunc(props.wordArray[ink].textColor)}
            animationDirection={false}
          >
            {letter}
          </Animate>
        </div>
      );
    }
  });
  return <Heading color={"red"}>{letters}</Heading>;
}
