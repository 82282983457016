import styled, { keyframes } from "styled-components";

const Animate = styled.span`
  animation: ${(props) => props.animate} 2s linear infinite;
  margin: 0em;
  animation-delay: ${(props) => props.delay};
  letter-spacing: 0em;
  display: inline;
  animation-direction: "normal";
  // font-size: 56px;
  color: white;
`;

const Animate2 = styled.span`
  animation: ${(props) => props.animate} ${(props) => props.animteTiming}
    ${(props) => props.timingFunction} infinite;
  ${({ delay }) =>
    delay &&
    `
     animation-delay: ${(props) => props.delay};
  `}
  // delay:'none';
  letter-spacing: 0em;
  display: inline;
  animation-direction: ${(props) =>
    props.animationDirection ? "alternate" : "normal"};
`;

const Animate3 = styled.span`
  animation: ${(props) => props.animate} 1s linear infinite;
  // ${({ delay }) =>
    delay &&
    `
     animation-delay: ${(props) => props.delay};
  // `}
  // animation-dalay:${(props) =>
    props.animationDalay ? `${props.delay}` : "none"};

  letter-spacing: 0em;
  display: inline;
  animation-direction: ${(props) =>
    props.animationDirection ? "alternate" : "normal"};
`;

const Animate4 = styled.span`
  animation: ${(props) => props.animate} 1s linear infinite;
  // ${({ delay }) =>
    delay &&
    `
     animation-delay: ${(props) => props.delay};
  // `}
  // animation-dalay:${(props) =>
    props.animationDalay ? `${props.delay}` : "none"};
  animation-dalay: "1s";

  letter-spacing: 0em;
  display: inline;
  animation-direction: ${(props) =>
    props.animationDirection ? "alternate" : "normal"};
`;

const Animate5 = styled.span`
  animation: ${(props) => props.animate} 0.5s linear infinite;
  ${({ delay }) =>
    delay &&
    `
  animation-delay: ${(props) => props.delay};
  `}
  animation-delay:${(props) => props.delay};
  letter-spacing: 0em;
  display: inline;
  animation-direction: "normal";
`;

const Animate7 = styled.span`
  animation: ${(props) => props.animate} 2s linear infinite;
  ${({ delay }) =>
    delay &&
    `
  animation-delay: ${(props) => props.delay};
  `}
  animation-delay:${(props) => props.delay};
  letter-spacing: 0em;
  display: inline;
  animation-direction: alternate;
`;

const Animate8 = styled.span`
  animation: ${(props) => props.animate} ${(props) => props.time + "s"} linear
    infinite;
  ${({ delay }) =>
    delay &&
    `
  animation-delay: ${(props) => props.delay};
  `}
  animation-delay:${(props) => props.delay};
  letter-spacing: 0em;
  display: inline;
  // animation-direction: alternate;
`;

export { Animate, Animate2, Animate3, Animate4, Animate5, Animate7, Animate8 };
