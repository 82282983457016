import React from "react";
import styled, { keyframes } from "styled-components";
import { Animate2 } from "../styledanimation/StyledAnimation";

const Heading = styled.h1`
  font-size: 0.5em;
  font-weight: 400;
`;
let animateFunc = (color) => {
  const animate = keyframes`
  0%{
      color: white;
      text-shadow: none;
      opacity:0.25;
  }
  100%{
      // color: #fff;
      opacity:1;
      color : ${color};
      text-shadow: 0 0 20px #fff,
       0 0 30px ${color},
        0 0 40px ${color},
        0 0 50px ${color},
         0 0 60px ${color}, 
         0 0 70px ${color}, 
         0 0 80px ${color};
  }
  `;
  return animate;
};

export default function Animations(props) {
  {
    return (
      // <Heading color={props.color}>
      <div className="centerDiv">
        {props.wordArray.map((itm) => (
          <div style={{ fontSize: `${itm.textSize}` }}>
            <Animate2
              delay={"none"}
              animate={animateFunc(itm.textColor)}
              animationDirection={true}
              animteTiming="0.1s"
              timingFunction={"linear"}
            >
              {itm.text} <br />
            </Animate2>
          </div>
        ))}
      </div>
      // </Heading>
    );
  }
}
