import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function CrossEffect(props) {
  let animate = () => {
    // let y = props.word;
    // let wordArr = y.split("");

    // let checkSpan = document.querySelectorAll("span");
    // if (checkSpan.length === 0) {
    //   for (var i = 0; i < wordArr.length; i++) {
    //     let span = document.createElement("span");
    //     span.id = `cross${i}`;
    //     span.innerHTML = wordArr[i];
    //     document.getElementById("wordBoxCross").appendChild(span);
    //   }
    // }

    // let allSpan = document.querySelectorAll("span");

    // allSpan.forEach((i) => {
    //   i.style.fontSize = "66px";
    //   i.style.color = "white";
    //   i.classList.add("glow");
    //   i.style.margin = "3px";
    // });
    // console.log(props.wordArray);

    let y = "";
    props.wordArray.map((itm, i) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxCross span");
    let ani = [];
    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `crossdiv${count}`;

      document.getElementById("wordBoxCross").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#crossdiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });
          ani.push(i);
          count++;
          var div = document.createElement("div");
          div.id = `crossdiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxCross").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `cross${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`cross${i}`, props.wordArray[count].textColor);
          document.getElementById(`crossdiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxCross span");

    var varTime = props.speed;

    function glow(ele, id) {
      ele.style.color = map1.get(id);
      ele.style.textShadow = `0px 0px 50px ${map1.get(
        id
      )}, 0px 0px 50px ${map1.get(id)}`;
    }

    function dim(ele, id) {
      ele.style.color = "white";
      ele.style.textShadow = "none";
    }

    let forwardCross = () => {
      for (let i = 0; i < wordArr.length; i++) {
        let ele = document.getElementById(`cross${i}`);
        let delay = i < wordArr.length / 2 ? i : i - 1;
        if (ele !== null) {
          if (wordArr.length % 2 == 0) {
            if (i === wordArr.length / 2) {
              continue;
            } else {
              setTimeout(() => {
                glow(ele, `cross${i}`);
                if (i !== wordArr.length - 2) {
                  setTimeout(() => {
                    dim(ele, `cross${i}`);
                  }, varTime * 10);
                }
              }, varTime * 10 * delay);
            }
          } else {
            setTimeout(() => {
              glow(ele, `cross${i}`);
              if (i !== wordArr.length - 2) {
                setTimeout(() => {
                  dim(ele, `cross${i}`);
                }, varTime * 10);
              }
            }, varTime * 10 * i);
          }
        }
      }
    };

    let backwardCross = () => {
      for (let i = wordArr.length - 1; i >= 0; i--) {
        let ele = document.getElementById(`cross${wordArr.length - 1 - i}`);
        let delay = i < wordArr.length / 2 ? i : i - 1;
        if (ele !== null) {
          if (wordArr.length % 2 === 0) {
            if (i === wordArr.length / 2) {
              continue;
            } else {
              setTimeout(() => {
                glow(ele, `cross${wordArr.length - 1 - i}`);
                if (i !== wordArr.length - 1) {
                  setTimeout(() => {
                    dim(ele, `cross${i}`);
                  }, varTime * 10);
                }
              }, varTime * 10 * delay);
            }
          } else {
            setTimeout(() => {
              glow(ele, `cross${wordArr.length - 1 - i}`);
              if (i !== wordArr.length - 1) {
                setTimeout(() => {
                  dim(ele, `cross${i}`);
                }, varTime * 10);
              }
            }, varTime * 10 * i);
          }
        }
      }
    };

    let cross = () => {
      forwardCross();
      backwardCross();
    };

    let anim = () => {
      for (let i = 0; i < 4; i++) {
        setTimeout(() => {
          cross();
        }, i * wordArr.length * varTime * 10);
      }
      setTimeout(() => {
        allSpan.forEach((ele) => {
          glow(ele);
        });
      }, wordArr.length * 4 * varTime * 10);
      setTimeout(() => {
        allSpan.forEach((ele) => {
          dim(ele);
        });
      }, wordArr.length * 4.3 * varTime * 10);
    };

    anim();
    let t = setInterval(() => {
      anim();
    }, wordArr.length * 4.4 * varTime * 10);
    return t;
  };
  useEffect(() => {
    let t = animate();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxCross"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default CrossEffect;
