import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function Threebythree(props) {
  let anim = () => {
    // let y = props.word;
    // let wordArr = y.split("");

    // let checkSpan = document.querySelectorAll("span");
    // if (checkSpan.length === 0) {
    //   for (var i = 0; i < wordArr.length; i++) {
    //     let span = document.createElement("span");
    //     span.id = `news${i}`;
    //     span.innerHTML = wordArr[i];
    //     document.getElementById("wordBoxNews").appendChild(span);
    //   }
    // }

    // let allSpan = document.querySelectorAll("span");

    // allSpan.forEach((i) => {
    //   i.style.fontSize = "66px";
    //   i.style.color = "white";
    //   i.classList.add("glow");
    //   i.style.margin = "3px";
    // });

    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxNews span");

    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `newsdiv${count}`;

      document.getElementById("wordBoxNews").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#newsdiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });
          let span = document.createElement("span");
          span.id = `news${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.classList.add("glow");
          span.innerHTML = wordArr[i];
          span.style.display = "none";
          // console.log(document.getElementById(`div${count}`));
          map1.set(`news${i}`, props.wordArray[count].textColor);
          document.getElementById(`newsdiv${count}`).appendChild(span);
          count++;
          var div = document.createElement("div");
          div.id = `newsdiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxNews").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `news${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.classList.add("glow");
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`news${i}`, props.wordArray[count].textColor);
          document.getElementById(`newsdiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxNews span");

    var varTime = props.speed;

    var col = [
      `${props.wordArray[0].textColor}`,
      `${props.wordArray[0].textColor}`,
      `${props.wordArray[0].textColor}`,
      "white",
      "white",
      "white",
    ];
    var k = 0;

    let main = () => {
      for (let i = wordArr.length - 1; i >= 1; i--) {
        // console.log(i);
        let a = document.getElementById(`news${i}`);
        let b = document.getElementById(`news${i - 1}`);
        if (a != null && b != null) {
          a.style.color = b.style.color;
          a.classList = b.classList;
          a.style.textShadow = b.style.textShadow;
        }
      }
      return new Promise((resolve, reject) => {
        resolve("1");
      });
    };

    // main();
    let t = setInterval(() => {
      main().then((res) => {
        let ds = document.getElementById("news0");
        if (ds != null) {
          ds.style.color = col[k];
          if (col[k] != "white") {
            ds.style.textShadow = `0px 0px 50px ${props.wordArray[0].textColor}, 0px 0px 50px ${props.wordArray[0].textColor}`;
          } else {
            ds.style.textShadow = "none";
          }
        }
        // console.log(k);
        k = (k + 1) % 6;
      });
    }, varTime * 10);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxNews"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default Threebythree;
