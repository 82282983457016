import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function SandGlass(props) {
  let animate = () => {
    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxSand span");

    let ani = [];
    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `sanddiv${count}`;

      document.getElementById("wordBoxSand").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#sanddiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });
          ani.push(i);
          count++;
          var div = document.createElement("div");
          div.id = `sanddiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxSand").appendChild(div);
        } else if (wordArr[i] === " ") {
          ani.push(i);
          let span = document.createElement("span");
          span.id = `sand${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";

          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`sand${i}`, props.wordArray[count].textColor);
          document.getElementById(`sanddiv${count}`).appendChild(span);
        } else {
          let span = document.createElement("span");
          span.id = `sand${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";

          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`sand${i}`, props.wordArray[count].textColor);
          document.getElementById(`sanddiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxSand span");
    // console.log(ani);
    var varTime = 5;
    async function task(i, j) {
      // 3
      let ds = document.getElementById(`sand${j}`);
      if (ds !== null) {
        ds.style.color = map1.get(`sand${j}`);
        ds.style.textShadow = `0px 0px 50px ${map1.get(
          `sand${j}`
        )}, 0px 0px 50px ${map1.get(`sand${j}`)}`;

        await timer(varTime * 20);
        ds.style.color = "white";
        ds.style.textShadow = "none";
      }
      //   console.log(ds);
    }

    async function main() {
      for (let i = 0; i < wordArr.length; i += 1) {
        if (ani.includes(i)) {
          continue;
        } else {
          for (let j = wordArr.length - 1; j >= i; j--) {
            await task(i, j);
          }
          let ds = document.getElementById(`sand${i}`);
          if (ds !== null) {
            ds.style.color = map1.get(`sand${i}`);
            ds.classList.add("glow");
            ds.style.textShadow = `0px 0px 50px ${map1.get(
              `sand${i}`
            )}, 0px 0px 50px ${map1.get(`sand${i}`)}`;
          }
        }
      }
    }
    main();
    function timer(ms) {
      return new Promise((res) => setTimeout(res, ms));
    }

    let t = setInterval(() => {
      allSpan.forEach((i) => {
        i.style.color = "white";
        i.classList.add("tsn");
      });
      main();
    }, varTime * 2 * 100 * wordArr.length);

    return t;
  };
  useEffect(() => {
    let t = animate();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxSand"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default SandGlass;
