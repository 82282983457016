import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function Twobytwo(props) {
  let anim = () => {
    // let y = props.word;
    // let wordArr = y.split("");

    // let checkSpan = document.querySelectorAll("span");
    // if (checkSpan.length === 0) {
    //   for (var i = 0; i < wordArr.length; i++) {
    //     let span = document.createElement("span");
    //     span.id = `two${i}`;
    //     span.innerHTML = wordArr[i];
    //     document.getElementById("wordBoxTwo").appendChild(span);
    //   }
    // }

    // let allSpan = document.querySelectorAll("span");

    // allSpan.forEach((i) => {
    //   i.style.fontSize = "66px";
    //   i.style.color = "yellow";
    //   i.classList.add("glow");

    //   i.classList.add("tso");
    //   i.style.margin = "3px";
    // });

    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxTwo span");

    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `twodiv${count}`;

      document.getElementById("wordBoxTwo").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#twodiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });

          let span = document.createElement("span");
          span.id = `two${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          span.style.display = "none";
          // console.log(document.getElementById(`div${count}`));
          map1.set(`two${i}`, props.wordArray[count].textColor);
          document.getElementById(`twodiv${count}`).appendChild(span);

          count++;
          var div = document.createElement("div");
          div.id = `twodiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxTwo").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `two${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`two${i}`, props.wordArray[count].textColor);
          document.getElementById(`twodiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxTwo span");

    var varTime = 10;

    function glow(ele, id) {
      ele.style.color = "white";
      ele.style.textShadow = "none";
    }

    function dim(ele, id) {
      ele.style.color = map1.get(id);
      ele.style.textShadow = `0px 0px 50px ${map1.get(
        id
      )}, 0px 0px 50px ${map1.get(id)}`;
    }

    let twobytwo = () => {
      let k;
      for (k = 1; k < wordArr.length; k++) {
        let j = k - 1;
        let ele2 = document.getElementById(`two${k}`);
        let ele1 = document.getElementById(`two${j}`);
        if (ele1 !== null && ele2 !== null) {
          setTimeout(() => {
            glow(ele1, `two${j}`);
            glow(ele2, `two${k}`);
            setTimeout(() => {
              dim(ele1, `two${j}`);
            }, varTime * 10);
          }, k * varTime * 10);
        }
      }

      setTimeout(() => {
        let ele = document.getElementById(`two${wordArr.length - 1}`);
        if (ele !== null) {
          ele.style.color = map1.get(`two${wordArr.length - 1}`);
        }
      }, wordArr.length * (varTime + 5) * 10);
    };

    twobytwo();
    let t = setInterval(() => {
      twobytwo();
    }, wordArr.length * (varTime + 5) * 10);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxTwo"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default Twobytwo;
