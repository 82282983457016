import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function Curtain2(props) {
  let anim = () => {
    let y = "";
    props.wordArray.map((itm) => {
      y += itm.text + "%";
    });
    let wordArr = y.split("");
    const map1 = new Map();
    let checkSpan = document.querySelectorAll("#wordBoxCurt span");
    // if (checkSpan.length === 0) {
    //   for (var i = 0; i < wordArr.length; i++) {
    //     let span = document.createElement("span");
    //     span.id = `cur${i}`;
    //     span.innerHTML = wordArr[i];
    //     document.getElementById("wordBoxCur").appendChild(span);
    //   }
    // }
    if (checkSpan.length === 0) {
      var count = 0;
      var div = document.createElement("div");

      // div.style.fontSize = props.wordArray[count].textSize;
      div.id = `curtdiv${count}`;

      document.getElementById("wordBoxCurt").appendChild(div);

      for (var i = 0; i < wordArr.length; i++) {
        if (wordArr[i] === "%") {
          let sd = document.querySelectorAll(`#curtdiv${count} span`);
          sd.forEach((itm) => {
            itm.style.fontSize = props.wordArray[count].textSize;
            itm.style.fontFamily = props.wordArray[count].textStyle;
          });

          count++;
          var div = document.createElement("div");
          div.id = `curtdiv${count}`;

          // div.style.fontSize = props.wordArray[count - 1].textSize;
          document.getElementById("wordBoxCurt").appendChild(div);
        } else {
          let span = document.createElement("span");
          span.id = `curt${i}`;
          span.style.fontSize = "1rem";
          span.style.color = "white";
          span.innerHTML = wordArr[i];
          // console.log(document.getElementById(`div${count}`));
          map1.set(`curt${i}`, props.wordArray[count].textColor);
          document.getElementById(`curtdiv${count}`).appendChild(span);
        }
      }
    }

    let allSpan = document.querySelectorAll("#wordBoxCurt span");

    var varTime = props.speed;

    let forwardAnimation = (start, end, color) => {
      for (let i = start; i <= end; i++) {
        let ds = document.getElementById(`curt${i}`);
        if (ds !== null) {
          setTimeout(() => {
            ds.style.color =
              color === "white" ? "white" : `${map1.get(`curt${i}`)}`;
            ds.classList.add("glow");

            if (color !== "white") {
              // ds.classList.add("tso");
              // ds.classList.remove("tsn");
              ds.style.textShadow = `0px 0px 50px ${map1.get(
                `curt${i}`
              )}, 0px 0px 50px ${map1.get(`curt${i}`)}`;
            } else {
              ds.style.textShadow = "none";
            }
          }, varTime * 10 * i);
        }
      }
    };

    let backwardAnimation = (start, end, color) => {
      for (let j = start; j >= end; j--) {
        let ds = document.getElementById(`curt${j}`);
        if (ds !== null) {
          ds.classList.add("glow");
          setTimeout(() => {
            ds.style.color =
              color === "white" ? "white" : `${map1.get(`curt${j}`)}`;

            if (color !== "white") {
              // ds.classList.add("tso");
              // ds.classList.remove("tsn");
              ds.style.textShadow = `0px 0px 50px ${map1.get(
                `curt${j}`
              )}, 0px 0px 50px ${map1.get(`curt${j}`)}`;
            } else {
              ds.style.textShadow = "none";
            }
          }, varTime * 10 * (wordArr.length - 1 - j));
        }
        // console.log(j);
      }
    };

    backwardAnimation(wordArr.length - 1, (wordArr.length - 1) / 2, "white");
    forwardAnimation(0, (wordArr.length - 1) / 2, "white");

    backwardAnimation(parseInt(wordArr.length / 2), 0, "yellow");
    forwardAnimation(
      parseInt((wordArr.length - 1) / 2),
      wordArr.length - 1,
      "yellow"
    );

    backwardAnimation(wordArr.length - 1, (wordArr.length - 1) / 2, "white");
    forwardAnimation(0, (wordArr.length - 1) / 2, "white");

    backwardAnimation(parseInt(wordArr.length / 2), 0, "yellow");
    forwardAnimation(
      parseInt((wordArr.length - 1) / 2),
      wordArr.length - 1,
      "yellow"
    );

    let t = setInterval(() => {
      backwardAnimation(wordArr.length - 1, (wordArr.length - 1) / 2, "white");
      forwardAnimation(0, (wordArr.length - 1) / 2, "white");

      backwardAnimation(parseInt(wordArr.length / 2), 0, "yellow");
      forwardAnimation(
        parseInt((wordArr.length - 1) / 2),
        wordArr.length - 1,
        "yellow"
      );
    }, wordArr.length * (varTime + 10) * 10);
    return t;
  };

  useEffect(() => {
    let t = anim();
    return () => {
      clearInterval(t);
    };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxCurt"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default Curtain2;
