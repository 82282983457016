import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

function InitialText(props) {
  let anim = () => {
    console.log(props.word);

    let span = document.createElement("span");
    span.id = `ini`;
    span.style.fontSize = "66px";
    span.style.color = "white";
    span.innerHTML = props.word;
    document.getElementById("wordBoxIni").appendChild(span);

    let allSpan = document.querySelectorAll("#wordBoxIni span");
    console.log(allSpan);

    var glowDimTime = 50;
    var repeatTime = glowDimTime + 10;
  };

  useEffect(() => {
    anim();
    // console.log(props.word);
    // let t = anim();
    // return () => {
    //   clearInterval(t);
    // };
  }, [props.speed]);
  return (
    <Heading color={"black"}>
      <div id="wordBoxIni"></div>
    </Heading>
  );
}

const Heading = styled.h1`
  font-size: 1em;
  font-weight: 400;
`;

export default InitialText;
